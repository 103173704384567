.modul-about {
  // margin-bottom: 20rem;

  &__title {
    margin-bottom: 5rem;
    color: $tc1;
    text-transform: uppercase;

    @include sm-max {
      margin-bottom: 2rem;
    }
  }

  &__columns {
    column-count: 2;
    column-gap: 5rem;

    @include sm-max {
      column-count: 1;
    }
  }
}

.modul-img {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  
  @extend .img--big;

  &--small {
    max-width: 1200px;
  }
}

.modul-features {
  &__title {
    color: $tc1;
    
    margin-bottom: 7.5rem;
    @include lg-max {
      margin-bottom: 5rem;
    }
    @include md-max {
      margin-bottom: 3rem;
    }
    @include sm-max {
      margin-bottom: 1rem;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include md-max {
      flex-direction: column;
    }
  }

  &__column {
    width: 33.33333%;
    margin-bottom: 3rem;

    &--four {
      width: 25%;

      @include lg-max {
        width: 50%;
        margin-bottom: 3rem;
      }
    }

    @include md-max {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      padding: 2rem;
    }
  }
  &__column:not(:last-child) {
    padding-right: 2rem;
  }

  &__subtitle {
    margin-bottom: 3rem;
    color: $tc1;

    @include md-max {
      margin-bottom: 1rem;
    }
  }
}

.modul-needs {
  // margin-bottom: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;

  @include lg-max {
    flex-direction: column;
    align-items: center;
  }

  @include md-max {
    font-size: 16px;
    line-height: 20px;
  }

  @include sm-max {
    font-size: 14px;
    line-height: 16px;
  }

  &__img {
    max-width: 50%;
    @include lg-max {
      margin-bottom: 3rem;
    }
  }

  &__text {
    max-width: 57.5rem;
    margin-left: 10rem;

    @include lg-max {
      margin-left: 2rem;
    }

    @include md-max {
      margin-left: 0;
    }
  }

  &__title {
    max-width: 50rem;
    margin-bottom: 5rem;
    color: $tc1;
    font-family: $ff-title;
    @include md-max {
      max-width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__list {
    width: 100%;
    columns: 2;

    @include sm-max {
      columns: 1;
    }

    li {
      margin-bottom: 2rem;
      padding: 0 25px;
      width: 100%;
      color: $tc1;
      font-family: $ff-text;
      font-weight: normal;
      font-size: 18px;
      letter-spacing: 0.3px;
      line-height: 28px;
      position: relative;

      @include sm-max {
        margin-left: 20px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        width: 5px;
        height: 5px;
        background-color: $accent1;
      }
    }
  }
}

.modul-tools {
  display: flex;
  justify-content: space-between;

  @include md-max {
    flex-direction: column;
  }

  &__text {
    max-width: 78.2rem;
  }

  &__title {
    padding-right: 2rem;
    color: $tc1;
    font-family: $ff-title;

    @include md-max {
      margin-bottom: 2rem;
    }
  }

  &__list {
    margin-top: 5rem;
    width: 100%;
    columns: 2;

    @include sm-max {
      columns: 1;
    }

    li {
      padding: 0 25px;
      width: 100%;
      color: $tc1;
      font-weight: 600;
      font-family: $ff-text;
      font-size: 18px;
      letter-spacing: 0.3px;
      line-height: 28px;
      position: relative;

      @include sm-max {
        margin-left: 20px;
        font-size: 16px;
        line-height: 20px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        width: 5px;
        height: 5px;
        background-color: $accent1;

        @include sm-max {
          top: 8px;
        }
      }
    }
  }
}

.modul-sequence {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;

  &.mbt0 {
    padding: 5rem 0 5rem 0px;
  }

  &__title {
    color: $tc1;
    font-family: $ff-title;
    text-transform: uppercase;
    margin-bottom: 5rem;

    @include sm-max {
      margin-bottom: 2rem;
    }
  }

  &__subtitle {
    margin-bottom: 2rem;
  }

  &__content {
    position: relative;
    margin: 0 auto;
    background-color: $accent1;
    
    strong {
      color: $tc1;
    }
  }

  &__list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10rem 15rem;
    display: flex;
    flex-direction: column;

    @include md-max {
      padding: 10rem 10rem;
    }

    @include sm-max {
      padding: 5rem;
    }
  }

  &__list li {
    position: relative;
    max-width: 64.5rem;
    padding-left: 14.5rem;

    @include sm-max {
      padding-left: 0;
    }
  }

  &__list li:not(:last-child) {
    margin-bottom: 5rem;
  }

  &__list li:nth-child(2n) {
    align-self: flex-end;
  }

  &__number {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    color: $tc1;
    font-family: $ff-title;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 48px;

    @include sm-max {
      font-size: 4rem;
      letter-spacing: 0;
      line-height: 4rem;
      position: relative;
      padding-bottom: 1rem;
    }
  }
}
