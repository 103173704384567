.right-tab {
    display: flex;

    @include md-max {
        flex-direction: column;
    }

    &__nav {
        display: flex;
        flex-direction: column;

        @include md-max {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__list,
    &__content {
        padding-top: 7.5rem;
        width: 50%;

        @include md-max {
            padding-top: 3rem;
            width: 100%;
        }
    }

    &__list {
        padding-right: 7.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include md-max {
            padding-right: 0;
        }
    }

    &__nav-item {
        @include md-max {
            width: 50%;
        }
    }
}
