.preview {
  display: flex;
  margin-bottom: 5rem;

  @include md-max {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  &__header {
    width: 50%;
    padding-right: 7.5rem;
    flex-shrink: 0;

    @include lg-max {
      width: 40%;
    }

    @include md-max {
      width: 100%;
      padding: 0 0 3rem;
    }
  }

  &__content {
    flex-grow: 1;
    padding-left: 2.5rem;
    color: $tc2;

    @include md-max {
      width: 100%;
      padding: 0;
    }
  }

  &__title {
    color: $tc1;
    // @include xl-max {
    //   font-size: 4.5rem;
    //   line-height: normal;
    // }

    // @include lg-max {
    //   font-size: 3.5rem;
    //   line-height: 3.5rem;
    // }

    // @include sm-max {
    //   font-size: 3.5rem;
    //   line-height: 3.5rem;
    // }
  }

  &__link {
    margin-top: 5rem;

    @include md-max {
      margin-top: 2rem;
    }
  }
}
