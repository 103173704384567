.logo {
  &--menu {
    position: fixed;
    top: 25px;
    left: 20px;
    z-index: 151;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.8s;

    @media (min-width: 768px) and (min-height: 550px) {
      top: 40px;
      // left: 30px;
    }

    @media (min-width: 1200px) and (min-height: 700px) {
      top: 60px;
      left: 60px;
    }
  }

  img {
    // width: 150px;
    width: 50px;
    margin-top: -10px;
    
    @media (min-width: 768px) and (min-height: 550px) {
      // width: 200px;
      width: 70px;
      margin-top: -10px;
    }

    @media (min-height: 700px) and (min-width: 1366px) {
      // width: 300px;
      width: 80px;
      margin-top: -20px;
    }
  }
}

.menu--open .logo {
  opacity: 1;
  pointer-events: auto;
}
