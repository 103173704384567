.main-header.scroll-remove {
  position: fixed;
}

.main-header.scroll-remove.out {
  transform: translateY(-100%);
  opacity: 0;
}

.main-header--light.scroll-remove {
  position: relative;
}

.main-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  transition: all 0.5s ease;

  .container-max {
    @include xl {
      // max-width: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: $gray;
    z-index: 2;
  }

  &--light {
    background-color: #fff;
    position: relative;
    
    &::before {
      background-color: transparent;
    }
  }

  &--white {
    &::before {
      background-color: #ffffff;
    }
  }

  &--dark {
    background-color: transparent;
    &::before {
      background-color: transparent;
    }

    .main-nav__link {
      color: $white;

      &::before {
        background-color: $white;
      }
    }

    .main-nav.active .main-nav__link {
      color: $tc1;
    }
  }

  &__body {
    padding: 0 2.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 14.4rem;

    @include xl-max {
      padding: 0 1rem;
    }
    @include lg-max {
      height: 12rem;
    }
    @include md-max {
      height: 10rem;
    }
    @include sm-max {
      height: 8rem;
    }
  }
  &__logo {
    flex: 0 0 26rem;
    position: relative;
    z-index: 3;
    margin-bottom: 1.5rem;

    @include sm-max {
      flex: 0 0 20rem;
    }
  }
  &__burger {
    display: none;
    cursor: pointer;

    @include lg-max {
      display: block;
      position: relative;
      width: 30px;
      height: 20px;
      z-index: 3;
    }

    &.active:before {
      transform: rotate(45deg);
      top: 9px;
    }

    &.active::after {
      transform: rotate(-45deg);
      bottom: 9px;
    }
    &.active span {
      transform: scale(0);
    }
  }

  &__burger span {
    @include lg-max {
      display: block;
      position: relative;
      width: 30px;
      height: 20px;
    }
  }

  &__burger span {
    background-color: $accent1;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;
    transition: all 0.3s ease;
  }

  &__burger::after,
  &__burger::before {
    @include lg-max {
      content: "";
      background-color: $accent1;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  &__burger::after {
    bottom: 0;
  }
  &__burger::before {
    top: 0;
  }
}

.main-header--light {
  .main-nav__link {
    color: $tc1;
    font-weight: 400;
    line-height: 14px;
    font-size: 16px;
    letter-spacing: 0.3px;
    text-transform: none;
  }

  .main-nav__list li.active .main-nav__link {
    // color: $accent2;
    font-weight: bold;
  }

  // .main-nav__link:hover {
  //   // color: $accent2;
  // }

  .main-nav__list li {
    margin: 0 0 0 5rem;
  }
}

.subtitle_content {
  margin: 3rem 0 0 0;
}
