.tab {
  display: none;
}

.tab.active {
  display: block;
}

.tabs {
  background-color: #fff;
  color: $tc2;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;

  &__nav {
    padding: 1rem;
    background-color: $tc3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    z-index: 20;

    // &.sticky {
    //     position: fixed;
    // }
  }

  &__link {
    padding: 1rem 2.5rem;
    color: $white;
    font-family: $ff-text;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 21px;
    text-align: center;
    opacity: 0.75;
    transition: color 0.2s ease, box-shadow 0.2s ease,
      background-color 0.2s ease;

    display: flex;
    align-items: center;
    background: transparent;

    &.active {
      color: #001c33;
      opacity: 1;
      //   font-weight: bold;
      margin-top: -2rem;
      margin-bottom: -2rem;
      background-color: #51dacd;
      height: 8rem;

      box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);

      @include lg-max {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__content {
    padding-top: 10rem;

    @include sm-max {
      padding-top: 15rem;
    }
  }
}
