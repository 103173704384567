.hero {
  padding-top: 15rem;
  margin-bottom: 8rem;
  
  @include md {
    margin-bottom: 10rem;
    padding-top: 20rem;
  }

  @include rwd(1200) {
    padding-top: 25rem;
  }
  
  @include xl {
    padding-top: 20rem;
  }

  &__inner {
    @extend .container-md;
  }

  &__text-block {
    color: $tc1;
    margin-bottom: 5rem;

    @include lg {
      margin-bottom: 8rem;
    }
  }

  &__title {
    color: inherit;
    text-transform: uppercase;
    margin: 0;
  }

  &--small-text &__title {
    font-size: 3rem;
    line-height: 1.2;

    @include sm {
      font-size: 4rem;
    }

    @include xl {
      font-size: 6rem;
    }
  }

  &__subtitle {
    display: block;
    margin-top: 30px;
    color: inherit;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: normal;

    @include sm {
      font-size: 3rem;
    }

    @include xl {
      font-size: 5rem;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 14px;

    @include md {
      font-size: 16px;
    }

    @include xl {
      font-size: 18px;
    }
  }

  &__img {
    @extend .img--big;

    // height: 50vw;

    // @include md {
    //   height: 40vw;
    // }

    // @include lg {
    //   height: 40vw;
    // }

    &--wide {
      max-width: 1680px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
