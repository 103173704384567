.contact-form {
  background-color: $bgc;

  .container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: $ff-text;
    font-size: 4.8rem;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 3rem;

    input {
      width: 100%;
    }
  }

  &__image {
    position: relative;
    top: -5rem;
    right: -5rem;

    @include sm-max {
      display: none;
    }

    img {
      max-height: 620px;
      height: 100%;
      max-width: none;
    }
  }

  &__title {
    margin-bottom: 5rem;
  }

  &__tiny-text {
    margin-top: 3rem;
    color: $tc3;
    font-family: $ff-text;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 28px;
  }

  &__link {
    color: $accent1;
    position: relative;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;

    &:after {
      display: inline-block;
      position: absolute;
      top: 100%;
      left: 0;
      content: "";
      height: 5px;
      width: 0;
      background-color: $accent1;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      opacity: 1;
      &:after {
        width: 100%;
      }
    }
  }
}

.contact-form-dark {
  margin: 0 auto;
  padding-top: 10rem;
  background-color: $tc1;
  color: #fff;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
  z-index: 1;

  @include md-max {
    padding-top: 10rem;
  }

  .contact-form__wrapper {
    display: flex;
    justify-content: space-between;

    @include md-max {
      flex-direction: column;
    }
  }

  &--simple {
    .contact-form__content {
      display: none;
    }
    .contact-form__wrapper {
      justify-content: center;
    }
  }

  .contact-form__title {
    font-family: $ff-title;
    font-size: 4rem;
    letter-spacing: 0;
    line-height: 4rem;
  }

  .contact-form__content {
    max-width: 43rem;

    @include md-max {
      width: 100%;
      max-width: 57.5rem;
      margin: 0 auto;
      text-align: left;
    }
  }

  #contact-form {
    min-width: auto;
    width: 50%;
    max-width: 57.5rem;
    @include md-max {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 10rem;
    }
  }

  #submit-contact-form {
    padding: 24px;
    width: 100%;
    background-color: $accent1;
    color: $tc1;
    font-family: $ff-title;
    font-size: 3rem;
    letter-spacing: 0;
    line-height: 5.2rem;
    position: relative;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 400;

    &:after {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 5px;
      width: 0;
      background-color: $accent1;
      transition: all 0.6s ease;
    }
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      height: 5px;
      width: 0;
      background-color: $accent1;
      transition: all 0.6s ease;
    }

    &:hover {
      text-decoration: none;
      background-color: $accent2;
      color: #fff;
      &:after,
      &:before {
        width: 100%;
      }
    }
  }

  #contact-form label {
    color: #ffffff;
    font-family: $ff-text;
    font-size: 2.4rem;
    font-weight: 300;
    letter-spacing: 0.4px;
    line-height: 3.6rem;
  }

  #contact-form input {
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    color: #ffffff;
  }

  .txta {
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    color: #ffffff;
  }
}

#contact-form {
  min-width: 66%;
  display: flex;
  flex-direction: column;

  label {
    color: $tc2;
    font-family: $ff-text;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.4px;
    line-height: 36px;
    transition: all 0.3s ease;
  }

  label.error {
    color: red;
    font-family: $ff-text;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  input {
    background-color: transparent;
    border-bottom: solid 1px rgba(47, 55, 79, 0.1);
    line-height: 3rem;
    font-size: 24px;
    letter-spacing: 1px;
  }

  .btn {
    align-self: flex-end;
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    
    @include md {
      font-size: 14px;
    }
  }
}

.upload-file {
  &__label {
    display: flex;
    justify-content: space-between;
    &:after {
      content: "";
      width: 32px;
      height: 3rem;
      background-image: url("../img/upload-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__name {
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
    min-height: 3rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    color: #ffffff;
    line-height: 3rem;
  }

  &__footnote {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  input {
    visibility: hidden;
  }

  .wpcf7-form-control-wrap {
    display: block;
  }

  .wpcf7-not-valid-tip {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // transform: translateY(-100%);
  }
}
