$ff-text: 'Nunito Sans', sans-serif;
$ff-title: 'Inter', sans-serif;

$trs: all 0.3s ease;

// colors

$black: #000;
$white: #fff;
$gray: #f4f5f9;

$bgc:#f9f8f4;

$accent1: #51dacd;
$accent2: #1a87e4;
$tc1: #001c33;
$tc2:#525d66;
$tc3: #7190a2; 
