.nav-bar {
  position: absolute;
  top: 25px;
  left: 20px;
  right: 20px;
  z-index: 99;

  @media only screen and (min-height: 550px) and (min-width: 768px) {
    top: 40px;
    // left: 30px;
    // right: 30px;
  }

  @media (min-height: 700px) and (min-width: 1200px) {
    top: 60px;
    left: 60px;
    right: 60px;
  }
}
