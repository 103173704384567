.block-list {
  padding: 8.7rem 0;
  background-color: $gray;

  @include sm-max {
    padding: 1.2rem 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.3rem;
  }

  &__item {
    padding: 1.3rem;
    flex: 0 0 33.3%;

    @include md-max {
      flex: 0 0 50%;
    }

    @include sm-max {
      flex: 0 0 100%;
    }
  }
  &__link {
    display: block;
    padding: 3rem;
    background-color: $white;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(0.95);
    }
  }

  &__name {
    margin-bottom: 1rem;
    color: $accent2;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 21px;
  }

  &__role {
    color: $tc3;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 14px;
  }
}
