.how-we-work {
    &__title-box {
        max-width: 78.2rem;
        margin: 0 auto 1rem;
        color: $tc2;
        font-family: $ff-text;
        font-size: 18px;
        letter-spacing: 0.3px;
        line-height: 28px;

        p {
            margin-bottom: 1rem;
        }
    }
    &__title {
        margin-bottom: 5rem;
        color: $tc1;
        font-family: $ff-title;
        text-align: center;
    }
}

.greeting-message {
    margin-bottom: 10rem;
    @include lg-max {
        margin-bottom: 8rem;
    }

    @include sm-max {
        margin-bottom: 5rem;
    }

    &__text {
        font-family: $ff-title;
        color: $black;
        font-size: 8rem;
        letter-spacing: 0;
        line-height: 8rem;
        text-align: center;

        @include rwd-max(1440) {
            font-size: 5.6vw;
            line-height: 5.6vw;
        }
    }
    img {
        width: 400%;
        @include lg-max {
            width: 100%;
        }
    }
}
