/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 1365px) {
  html {
    font-size: 50%;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button:not(:disabled) {
  cursor: pointer;
}

button:focus {
  outline: none;
}

body {
  display: block;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  background: #fff;
  min-width: 320px;
  color: #001c33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 1.8rem;
}

.site-wrapper {
  height: 100vh;
  background-color: #fff;
}

.site-wrapper__inner {
  width: 100vw;
  overflow-x: hidden;
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 0.7;
}

:-ms-input-placeholder {
  opacity: 0.7;
}

::ms-input-placeholder {
  opacity: 0.7;
}

h1,
h2 {
  color: #51dacd;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  user-select: none;
}

h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 4rem;
  line-height: 1.2;
}

@media only screen and (min-width: 576px) {
  h1 {
    font-size: 6rem;
  }
}

@media only screen and (min-width: 1366px) {
  h1 {
    font-size: 8rem;
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.2;
}

@media only screen and (min-width: 576px) {
  h2 {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 1366px) {
  h2 {
    font-size: 6rem;
  }
}

h3 {
  font-size: 3rem;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

h4 {
  font-size: 2.8rem;
  line-height: 3rem;
}

@media only screen and (max-width: 575px) {
  h4 {
    font-size: 2.2rem;
    line-height: 1.3;
  }
}

h5 {
  font-size: 2.4rem;
  line-height: 2.6rem;
}

@media only screen and (max-width: 575px) {
  h5 {
    font-size: 2rem;
  }
}

p {
  font-size: 18px;
  line-height: 1.5;
}

@media only screen and (max-width: 991px) {
  p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  p {
    font-size: 14px;
  }
}

.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.df {
  display: flex;
}

.ovh {
  overflow: hidden;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}

.preloader {
  display: none;
}

.js .preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-transform: uppercase;
}

.main-header.scroll-remove {
  position: fixed;
}

.main-header.scroll-remove.out {
  transform: translateY(-100%);
  opacity: 0;
}

.main-header--light.scroll-remove {
  position: relative;
}

.main-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  transition: all 0.5s ease;
}

.main-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #f4f5f9;
  z-index: 2;
}

.main-header--light {
  background-color: #fff;
  position: relative;
}

.main-header--light::before {
  background-color: transparent;
}

.main-header--white::before {
  background-color: #ffffff;
}

.main-header--dark {
  background-color: transparent;
}

.main-header--dark::before {
  background-color: transparent;
}

.main-header--dark .main-nav__link {
  color: #fff;
}

.main-header--dark .main-nav__link::before {
  background-color: #fff;
}

.main-header--dark .main-nav.active .main-nav__link {
  color: #001c33;
}

.main-header__body {
  padding: 0 2.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14.4rem;
}

@media only screen and (max-width: 1365px) {
  .main-header__body {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 991px) {
  .main-header__body {
    height: 12rem;
  }
}

@media only screen and (max-width: 767px) {
  .main-header__body {
    height: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .main-header__body {
    height: 8rem;
  }
}

.main-header__logo {
  flex: 0 0 26rem;
  position: relative;
  z-index: 3;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 575px) {
  .main-header__logo {
    flex: 0 0 20rem;
  }
}

.main-header__burger {
  display: none;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .main-header__burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 3;
  }
}

.main-header__burger.active:before {
  transform: rotate(45deg);
  top: 9px;
}

.main-header__burger.active::after {
  transform: rotate(-45deg);
  bottom: 9px;
}

.main-header__burger.active span {
  transform: scale(0);
}

@media only screen and (max-width: 991px) {
  .main-header__burger span {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
  }
}

.main-header__burger span {
  background-color: #51dacd;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 9px;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 991px) {
  .main-header__burger::after, .main-header__burger::before {
    content: "";
    background-color: #51dacd;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease;
  }
}

.main-header__burger::after {
  bottom: 0;
}

.main-header__burger::before {
  top: 0;
}

.main-header--light .main-nav__link {
  color: #001c33;
  font-weight: 400;
  line-height: 14px;
  font-size: 16px;
  letter-spacing: 0.3px;
  text-transform: none;
}

.main-header--light .main-nav__list li.active .main-nav__link {
  font-weight: bold;
}

.main-header--light .main-nav__list li {
  margin: 0 0 0 5rem;
}

.subtitle_content {
  margin: 3rem 0 0 0;
}

/*=== containers ===*/
.container-base, .container-max, .container-md, .hero__inner, .container, .container-s, .container-xs {
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 768px) and (min-height: 550px) {
  .container-base, .container-max, .container-md, .hero__inner, .container, .container-s, .container-xs {
    padding: 0 30px;
  }
}

@media (min-width: 1200px) and (min-height: 700px) {
  .container-base, .container-max, .container-md, .hero__inner, .container, .container-s, .container-xs {
    padding: 0 60px;
  }
}

.container-max {
  max-width: 1440px;
}

.container-md, .hero__inner {
  max-width: 1250px;
}

.container {
  max-width: 1074px;
}

.container-s {
  max-width: 975px;
}

.container-xs {
  max-width: 832px;
}

/*=== block scroll ===*/
body.lock {
  overflow: hidden;
}

/*=== darken image ===*/
.darkened {
  display: block;
  position: relative;
}

.darkened::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: all 0.7s ease;
}

/*=== buttons ===*/
.btn {
  display: block;
  color: #51dacd;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 18px;
  background-color: transparent;
  transition: all 0.3s ease;
  position: relative;
}

.btn--center {
  text-align: center;
}

.btn:hover {
  text-decoration: underline;
}

.btn-rectangle {
  min-width: 226px;
  padding: 23px;
  border-radius: 30px;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  background-color: #1a87e4;
  transition: all 0.3s ease;
  position: relative;
  text-transform: uppercase;
}

.btn-rectangle:hover {
  opacity: 0.7;
}

/*=== textaera autoheight ===*/
.txta {
  width: 100%;
  max-width: 100%;
  min-height: 30px;
  background-color: transparent;
  font-family: "Nunito Sans", sans-serif;
  overflow: hidden;
  border-bottom: solid 1px rgba(47, 55, 79, 0.1);
  line-height: 3rem;
  font-size: 24px;
}

/*=== block global padding ===*/
.pb10 {
  padding-bottom: 10rem;
}

@media only screen and (max-width: 991px) {
  .pb10 {
    padding-bottom: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .pb10 {
    padding-bottom: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .pb10 {
    padding-bottom: 3rem;
  }
}

.pt5 {
  padding-top: 5rem;
}

.pb5 {
  padding-bottom: 5rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.pt10 {
  padding-top: 10rem;
}

@media only screen and (max-width: 1365px) {
  .pt10 {
    padding-top: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .pt10 {
    padding-top: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .pt10 {
    padding-top: 3rem;
  }
}

.pt20 {
  padding-top: 20rem;
}

@media only screen and (max-width: 1365px) {
  .pt20 {
    padding-top: 15rem;
  }
}

@media only screen and (max-width: 767px) {
  .pt20 {
    padding-top: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .pt20 {
    padding-top: 5rem;
  }
}

.mt10 {
  margin-top: 10rem;
}

@media only screen and (max-width: 1365px) {
  .mt10 {
    margin-top: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .mt10 {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .mt10 {
    margin-top: 3rem;
  }
}

.mt20 {
  margin-top: 10rem;
}

@media only screen and (max-width: 1365px) {
  .mt20 {
    margin-top: 15rem;
  }
}

@media only screen and (max-width: 767px) {
  .mt20 {
    margin-top: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .mt20 {
    margin-top: 5rem;
  }
}

.mb20 {
  margin-bottom: 20rem;
}

@media only screen and (max-width: 991px) {
  .mb20 {
    margin-bottom: 15rem;
  }
}

@media only screen and (max-width: 767px) {
  .mb20 {
    margin-bottom: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .mb20 {
    margin-bottom: 5rem;
  }
}

.mb10 {
  margin-bottom: 10rem;
}

@media only screen and (max-width: 1365px) {
  .mb10 {
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .mb10 {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .mb10 {
    margin-bottom: 3rem;
  }
}

.img-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100%;
    height: 100%;*/
}

.img-title {
  padding-left: 5rem;
  padding-right: 5rem;
}

.light-text {
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 21px;
}

@media only screen and (max-width: 575px) {
  .light-text {
    font-size: 14px;
    line-height: 16px;
  }
}

.text-link {
  color: #001c33;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  transition: all 0.3 ease-in-out;
}

@media only screen and (max-width: 575px) {
  .text-link {
    font-size: 14px;
    line-height: 16px;
  }
}

.text-link:hover, .text-link:focus {
  opacity: 0.6;
}

.modul-about ul li,
.modul-features ul li,
.modul-needs ul li,
.how-we-work__title-box ul li,
.features__block ul li,
.text-block ul li,
.img-module__content ul li,
.history-module__content ul li,
.app-about ul li {
  padding: 0 25px;
  width: 100%;
  color: #525d66;
  font-weight: normal;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .modul-about ul li,
  .modul-features ul li,
  .modul-needs ul li,
  .how-we-work__title-box ul li,
  .features__block ul li,
  .text-block ul li,
  .img-module__content ul li,
  .history-module__content ul li,
  .app-about ul li {
    margin-left: 20px;
    font-size: 14px;
    line-height: 16px;
  }
}

.modul-about ul li::before,
.modul-features ul li::before,
.modul-needs ul li::before,
.how-we-work__title-box ul li::before,
.features__block ul li::before,
.text-block ul li::before,
.img-module__content ul li::before,
.history-module__content ul li::before,
.app-about ul li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #51dacd;
}

@media only screen and (max-width: 575px) {
  .modul-about ul li::before,
  .modul-features ul li::before,
  .modul-needs ul li::before,
  .how-we-work__title-box ul li::before,
  .features__block ul li::before,
  .text-block ul li::before,
  .img-module__content ul li::before,
  .history-module__content ul li::before,
  .app-about ul li::before {
    top: 6px;
  }
}

.modul-about ul li:not(:last-child),
.modul-features ul li:not(:last-child),
.modul-needs ul li:not(:last-child),
.how-we-work__title-box ul li:not(:last-child),
.features__block ul li:not(:last-child),
.text-block ul li:not(:last-child),
.img-module__content ul li:not(:last-child),
.history-module__content ul li:not(:last-child),
.app-about ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.modul-about ul,
.modul-features ul,
.modul-needs ul,
.how-we-work__title-box ul,
.features__block ul,
.text-block ul,
.img-module__content ul,
.history-module__content ul,
.app-about ul {
  margin: 2rem 1rem 2rem 0rem;
}

@media only screen and (max-width: 575px) {
  .modul-about ul,
  .modul-features ul,
  .modul-needs ul,
  .how-we-work__title-box ul,
  .features__block ul,
  .text-block ul,
  .img-module__content ul,
  .history-module__content ul,
  .app-about ul {
    margin: 2rem 0 1rem;
  }
}

.hero {
  padding-top: 15rem;
  margin-bottom: 8rem;
}

@media only screen and (min-width: 768px) {
  .hero {
    margin-bottom: 10rem;
    padding-top: 20rem;
  }
}

@media (min-width: 1200px) {
  .hero {
    padding-top: 25rem;
  }
}

@media only screen and (min-width: 1366px) {
  .hero {
    padding-top: 20rem;
  }
}

.hero__text-block {
  color: #001c33;
  margin-bottom: 5rem;
}

@media only screen and (min-width: 992px) {
  .hero__text-block {
    margin-bottom: 8rem;
  }
}

.hero__title {
  color: inherit;
  text-transform: uppercase;
  margin: 0;
}

.hero--small-text .hero__title {
  font-size: 3rem;
  line-height: 1.2;
}

@media only screen and (min-width: 576px) {
  .hero--small-text .hero__title {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 1366px) {
  .hero--small-text .hero__title {
    font-size: 6rem;
  }
}

.hero__subtitle {
  display: block;
  margin-top: 30px;
  color: inherit;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: normal;
}

@media only screen and (min-width: 576px) {
  .hero__subtitle {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1366px) {
  .hero__subtitle {
    font-size: 5rem;
  }
}

.hero__text {
  margin-top: 20px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .hero__text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1366px) {
  .hero__text {
    font-size: 18px;
  }
}

.hero__img--wide {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
}

.home-hero {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.home-hero__content {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 0 15rem;
  position: relative;
  z-index: 1;
}

.home-hero__award {
  display: block;
  width: 40vw;
  max-width: 350px;
  margin: 0 auto;
}

.home-hero__img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.home-hero__title {
  color: #fff;
  text-transform: lowercase;
  margin: 0;
  line-height: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.home-hero__title span {
  display: block;
}

.home-hero__title span:first-child {
  font-size: 11rem;
}

@media only screen and (max-width: 991px) {
  .home-hero__title span:first-child {
    font-size: 9vw;
  }
}

.home-hero__title span:not(:first-child) {
  font-size: 6rem;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {
  .home-hero__title span:not(:first-child) {
    font-size: 4.6vw;
  }
}

.hero-media {
  position: relative;
  padding-top: 16rem;
  background-color: #fff;
}

@media only screen and (min-width: 992px) {
  .hero-media {
    padding-top: 20rem;
  }
}

.hero-media img {
  position: relative;
}

.hero-media__box {
  max-width: 120rem;
  margin: 5rem auto 0;
  position: relative;
}

@media only screen and (max-width: 1365px) {
  .hero-media__box {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .hero-media__box {
    padding-top: 2rem;
    margin: 0 auto;
  }
}

.hero-media__box--lg {
  padding: 18rem 0 0;
}

@media only screen and (max-width: 767px) {
  .hero-media__box--lg {
    padding-top: 2rem;
  }
}

.hero-media__text {
  width: 100%;
  padding: 0;
  margin: 0 auto 5rem;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .hero-media__text {
    position: relative;
  }
}

.hero-media h1 {
  color: #001c33;
  text-transform: none;
  text-align: center;
}

.main {
  background-color: #fff;
  position: relative;
}

.main__title {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .main__title {
    text-align: center;
  }
}

.main__title span {
  display: block;
}

.main__subtitle {
  margin: 0;
  color: #001c33;
  font-family: "Nunito Sans", sans-serif;
  font-size: 5rem;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 7.1rem;
}

@media only screen and (max-width: 767px) {
  .main__subtitle {
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .main__subtitle {
    font-size: 4rem;
    line-height: 5.1rem;
  }
}

.main__subtitle__nav {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.main__subtitle__link {
  margin: 0 3.5rem;
  color: #1a87e4;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.main__subtitle__link:hover, .main__subtitle__link.active {
  color: #51dacd;
}

.main__tags-list {
  padding-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .main__tags-list {
    justify-content: center;
  }
}

.main__tag {
  margin-right: 1.5rem;
  color: #51dacd;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 24px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 575px) {
  .main__tag {
    font-size: 16px;
    margin-right: 1.5rem;
  }
  .main__tag:not(:first-child) {
    margin-left: 1.5rem;
  }
}

.main__tag.active {
  color: #1a87e4;
}

.main__posts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: center 30rem;
}

@media only screen and (max-width: 767px) {
  .main__posts {
    flex-direction: column;
    padding-bottom: 1rem;
  }
}

.post {
  padding-bottom: 5rem;
  position: relative;
  flex: 0 0 49%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.post.even {
  top: 10rem;
}

@media only screen and (max-width: 767px) {
  .post.even {
    top: 0;
  }
}

.post__image {
  margin-bottom: 3.7rem;
  transition: all 0.7s ease;
  position: relative;
}

.post__image:hover {
  transform: scale(0.95);
}

.post__image:hover .darkened::before {
  background-color: rgba(0, 0, 0, 0);
}

.post__image-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.post__title, .post__category {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.post__title {
  margin-bottom: 1.9rem;
  color: #001c33;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0.53px;
}

.post__category {
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.post-bg {
  text-align: center;
  z-index: 0;
  font-family: "Inter", sans-serif;
  font-size: 12rem;
  margin-bottom: 20rem;
}

.post-bg.sticky {
  position: fixed;
}

.post-bg.sticky {
  position: fixed;
}

@media only screen and (max-width: 991px) {
  .post-bg {
    display: none;
  }
}

span.subtitle_ {
  font-size: 50px;
  font-family: "Inter", sans-serif;
}

#wpcf7-f1248-o1 {
  max-width: 100%;
}

.wpcf7-response-output {
  display: none !important;
}

.back-to-top {
  position: fixed;
  background-color: #1a87e4;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top img {
  height: 22px;
  transition: transform .2s ease;
}

.back-to-top:hover img {
  transform: scale(1.2);
}

.back-to-top:hover {
  opacity: 0.7;
}

p strong,
strong,
p b {
  font-weight: 600;
}

.contact-form {
  background-color: #f9f8f4;
}

.contact-form .container {
  display: flex;
  justify-content: space-between;
}

.contact-form__title {
  font-family: "Nunito Sans", sans-serif;
  font-size: 4.8rem;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 5rem;
}

.contact-form__item {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.contact-form__item input {
  width: 100%;
}

.contact-form__image {
  position: relative;
  top: -5rem;
  right: -5rem;
}

@media only screen and (max-width: 575px) {
  .contact-form__image {
    display: none;
  }
}

.contact-form__image img {
  max-height: 620px;
  height: 100%;
  max-width: none;
}

.contact-form__title {
  margin-bottom: 5rem;
}

.contact-form__tiny-text {
  margin-top: 3rem;
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

.contact-form__link {
  color: #51dacd;
  position: relative;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.contact-form__link:after {
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  height: 5px;
  width: 0;
  background-color: #51dacd;
  transition: all 0.3s ease-in-out;
}

.contact-form__link:hover {
  opacity: 1;
}

.contact-form__link:hover:after {
  width: 100%;
}

.contact-form-dark {
  margin: 0 auto;
  padding-top: 10rem;
  background-color: #001c33;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .contact-form-dark {
    padding-top: 10rem;
  }
}

.contact-form-dark .contact-form__wrapper {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .contact-form-dark .contact-form__wrapper {
    flex-direction: column;
  }
}

.contact-form-dark--simple .contact-form__content {
  display: none;
}

.contact-form-dark--simple .contact-form__wrapper {
  justify-content: center;
}

.contact-form-dark .contact-form__title {
  font-family: "Inter", sans-serif;
  font-size: 4rem;
  letter-spacing: 0;
  line-height: 4rem;
}

.contact-form-dark .contact-form__content {
  max-width: 43rem;
}

@media only screen and (max-width: 767px) {
  .contact-form-dark .contact-form__content {
    width: 100%;
    max-width: 57.5rem;
    margin: 0 auto;
    text-align: left;
  }
}

.contact-form-dark #contact-form {
  min-width: auto;
  width: 50%;
  max-width: 57.5rem;
}

@media only screen and (max-width: 767px) {
  .contact-form-dark #contact-form {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10rem;
  }
}

.contact-form-dark #submit-contact-form {
  padding: 24px;
  width: 100%;
  background-color: #51dacd;
  color: #001c33;
  font-family: "Inter", sans-serif;
  font-size: 3rem;
  letter-spacing: 0;
  line-height: 5.2rem;
  position: relative;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 400;
}

.contact-form-dark #submit-contact-form:after {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 5px;
  width: 0;
  background-color: #51dacd;
  transition: all 0.6s ease;
}

.contact-form-dark #submit-contact-form:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 5px;
  width: 0;
  background-color: #51dacd;
  transition: all 0.6s ease;
}

.contact-form-dark #submit-contact-form:hover {
  text-decoration: none;
  background-color: #1a87e4;
  color: #fff;
}

.contact-form-dark #submit-contact-form:hover:after, .contact-form-dark #submit-contact-form:hover:before {
  width: 100%;
}

.contact-form-dark #contact-form label {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 3.6rem;
}

.contact-form-dark #contact-form input {
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.contact-form-dark .txta {
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

#contact-form {
  min-width: 66%;
  display: flex;
  flex-direction: column;
}

#contact-form label {
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 36px;
  transition: all 0.3s ease;
}

#contact-form label.error {
  color: red;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 24px;
}

#contact-form input {
  background-color: transparent;
  border-bottom: solid 1px rgba(47, 55, 79, 0.1);
  line-height: 3rem;
  font-size: 24px;
  letter-spacing: 1px;
}

#contact-form .btn {
  align-self: flex-end;
}

#contact-form .wpcf7-not-valid-tip {
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  #contact-form .wpcf7-not-valid-tip {
    font-size: 14px;
  }
}

.upload-file__label {
  display: flex;
  justify-content: space-between;
}

.upload-file__label:after {
  content: "";
  width: 32px;
  height: 3rem;
  background-image: url("../img/upload-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.upload-file__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-file__name {
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
  min-height: 3rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  color: #ffffff;
  line-height: 3rem;
}

.upload-file__footnote {
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.upload-file input {
  visibility: hidden;
}

.upload-file .wpcf7-form-control-wrap {
  display: block;
}

.main-footer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 7.5rem;
  overflow: hidden;
  background: #fff;
}

@media only screen and (max-width: 991px) {
  .main-footer {
    padding: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .main-footer {
    flex-direction: column-reverse;
  }
}

.main-footer__logo {
  width: 240px;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .main-footer__logo {
    margin-top: 15px;
  }
}

.main-footer__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 767px) {
  .main-footer__top {
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .main-footer__awards {
    margin-top: 5rem;
  }
}

.main-footer__awards > *:not(:last-child) {
  margin-right: 2rem;
}

.main-footer__awards a:hover img {
  transform: scale(0.95);
}

.main-footer__awards img {
  transition: transform .3s ease;
  height: 8rem;
}

.main-footer__bottom-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
}

@media only screen and (max-width: 991px) {
  .main-footer__bottom-text {
    flex-direction: column;
  }
}

.main-footer__bottom-text a {
  text-decoration: underline;
  color: inherit;
}

.main-footer__bottom-text p {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .main-footer__bottom-text p {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .main-footer__bottom-text p > * {
    margin-bottom: 1rem;
  }
}

.main-footer__bottom-text p > *:not(:last-child) {
  margin-right: 2rem;
}

@media only screen and (max-width: 767px) {
  .main-footer__bottom-text p > *:not(:last-child) {
    margin-right: 0;
  }
}

.main-nav__list {
  display: flex;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 991px) {
  .main-nav__list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.main-nav__list li {
  margin: 0 0 0 5rem;
}

@media only screen and (max-width: 991px) {
  .main-nav__list li {
    margin: 0;
  }
}

.main-nav__link {
  display: inline-block;
  color: #001c33;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 14px;
  transition: all 0.3s ease-in;
  position: relative;
  user-select: none;
}

.main-nav__link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #001c33;
  transition: all 0.2s ease;
  width: 0;
}

.main-nav__link:hover::before {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .main-nav__link {
    padding: 2rem;
  }
}

.main-nav__list li.active .main-nav__link::before {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .main-nav {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    padding: 12rem 0 0;
    transition: all 0.5s ease;
  }
}

.main-nav.active {
  top: 0;
}

.second-nav__list {
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
  .second-nav__list {
    padding: 2rem 0;
  }
}

.second-nav__list li.active .main-nav__link {
  font-weight: bold;
  color: #51dacd;
}

.second-nav__link {
  display: inline-block;
  margin: 1rem 3.5rem;
  color: #1a87e4;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 18px;
  transition: all 0.3s ease;
}

.second-nav__link:hover {
  color: #51dacd;
}

.second-nav__link.active {
  font-weight: bold;
  color: #51dacd;
}

.error-page {
  min-height: calc(100vh - 300px);
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .error-page {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media only screen and (max-width: 575px) {
  .error-page {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
}

.error-page .container-max {
  width: 100%;
  min-height: 24.8rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% center;
}

@media (max-width: 1440px) {
  .error-page .container-max {
    min-height: 19.4vw;
    background-size: 63%;
    background-position: right center;
  }
}

@media only screen and (max-width: 767px) {
  .error-page .container-max {
    padding-top: 30%;
    background-position: top center;
    background-size: 70%;
  }
}

.error-page .container-max .main__title {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1365px) {
  .error-page .container-max .main__title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .error-page .container-max .main__title {
    margin-bottom: 1rem;
  }
}

.error-page__text {
  max-width: 33%;
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .error-page__text {
    max-width: 100%;
    text-align: center;
  }
}

.error-page__btn {
  margin-top: 5rem;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .error-page__btn {
    justify-content: center;
  }
}

.article-page .container-max {
  min-height: auto;
}

.article-page .container-max {
  padding: 0;
}

.hero + .app-details {
  margin-top: -3rem;
}

.app-details {
  border-bottom: 1px solid #e6e9f2;
}

.app-details__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

@media only screen and (min-width: 768px) {
  .app-details__list {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.app-details__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 2rem;
}

@media (min-width: 400px) {
  .app-details__item {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .app-details__item {
    width: auto;
    padding: 0 1rem;
  }
}

.app-details__title {
  margin-bottom: 2rem;
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 24px;
}

@media only screen and (max-width: 991px) {
  .app-details__title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .app-details__title {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}

.app-details__text {
  color: #001c33;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  max-width: 300px;
  line-height: 1.2;
}

@media only screen and (max-width: 991px) {
  .app-details__text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .app-details__text {
    font-size: 16px;
  }
}

.app-about {
  display: flex;
  color: #525d66;
}

@media only screen and (max-width: 767px) {
  .app-about {
    flex-direction: column;
  }
}

.app-about__title {
  padding-right: 2rem;
  flex-basis: 35%;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .app-about__title {
    margin-bottom: 5rem;
  }
}

.app-about__content {
  flex-grow: 1;
}

.app-about__text p {
  margin-bottom: 1rem;
  flex-grow: 1;
}

.app-about__tags-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.app-about__tag {
  margin-right: 3rem;
  color: #51dacd;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 575px) {
  .app-about__tag {
    font-size: 16px;
    margin-right: 1.5rem;
  }
}

.app-about__tag:hover {
  opacity: 0.7;
}

.app-about__list {
  width: 100%;
  columns: 2;
}

@media only screen and (max-width: 575px) {
  .app-about__list {
    columns: 1;
  }
}

.app-about__list li {
  margin-bottom: 2rem;
  padding: 0 25px;
  width: 100%;
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .app-about__list li {
    margin-left: 20px;
  }
}

.app-about__list li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #51dacd;
}

.quote-block {
  background-color: #1a87e4;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-block p {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

.quote-block .container {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .quote-block .container {
    flex-direction: column-reverse;
  }
}

.quote-block__title {
  max-width: 50rem;
  padding-right: 3rem;
  margin-bottom: 2rem;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 4.8rem;
  letter-spacing: 0;
  line-height: 4.8rem;
}

@media only screen and (max-width: 767px) {
  .quote-block__title {
    max-width: 100%;
    padding-right: 0;
    padding-left: 60px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .quote-block__title {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.quote-block__left {
  flex: 1 1 50%;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .quote-block__left {
    text-align: right;
  }
}

.quote-block__right {
  flex: 1 1 50%;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .quote-block__right {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .quote-block__right {
    text-align: center;
    padding-left: 0;
  }
}

.quote-block__img {
  padding-right: 5.5rem;
  width: 60%;
  position: absolute;
  top: -3rem;
  left: -52%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .quote-block__img {
    padding-right: 0;
    width: 80px;
    top: -2rem;
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .quote-block__img {
    width: 80px;
    top: -3rem;
    left: -15px;
  }
}

.text-block__title {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 575px) {
  .text-block__title {
    margin-bottom: 3rem;
  }
}

.text-block__title--image {
  margin-bottom: 20rem;
}

@media only screen and (max-width: 991px) {
  .text-block__title--image {
    margin-bottom: 15rem;
  }
}

@media only screen and (max-width: 767px) {
  .text-block__title--image {
    margin-bottom: 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .text-block__title--image {
    margin-bottom: 5rem;
  }
}

.text-block__text {
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

.text-block__text--container {
  max-width: 832px;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.text-block__text p:not(:last-child) {
  margin-bottom: 1rem;
}

.pt5 {
  padding-top: 5rem;
}

.article-slider {
  max-width: 1440px;
  margin: 0 auto;
}

.article-slider .main__subtitle {
  margin-bottom: 11.5rem;
}

.slider .slick-list {
  padding: 0 15% !important;
}

.slider__item {
  margin: 0 2.5rem;
}

@media only screen and (max-width: 767px) {
  .slider__item {
    margin: 0 1.5rem;
  }
}

.slider__item img {
  margin: 0 auto;
}

.app-team__title {
  max-width: 708px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .app-team__title {
    text-align: center;
  }
}

.app-team__block {
  padding: 10rem 0;
  background-color: #f4f5f9;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.app-team__block .container {
  max-width: 1050px;
}

.app-team__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2.6rem;
  margin-top: -2.6rem;
}

@media only screen and (max-width: 575px) {
  .app-team__items {
    margin-left: 0;
    margin-top: 0;
  }
}

.app-team__item {
  margin-left: 2.6rem;
  margin-top: 2.6rem;
  padding: 3rem;
  flex: 0 0 calc(33.3% - 2.6rem);
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .app-team__item {
    flex: 0 0 calc(50% - 2.6rem);
  }
}

@media only screen and (max-width: 575px) {
  .app-team__item {
    flex: 0 0 100%;
    margin-left: 0;
  }
}

.app-team__name {
  margin-bottom: 1rem;
  color: #1a87e4;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 21px;
}

.app-team__role {
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.app-gallery {
  padding: 10rem 0 0 0;
}

@media only screen and (max-width: 767px) {
  .app-gallery {
    padding: 5rem 0 10rem 0;
  }
}

@media only screen and (max-width: 767px) {
  .app-gallery .container {
    padding: 0;
  }
}

.app-gallery__list {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .app-gallery__list .slick-list {
    padding: 0 15% !important;
  }
}

.app-gallery__list.small {
  justify-content: center;
}

.app-gallery__item {
  padding: 0 1.3rem;
  margin-bottom: 2.6rem;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .app-gallery__item {
    width: auto;
    padding: 0;
    margin: 0 1.5rem;
  }
}

.app-gallery__item:nth-child(2n) {
  top: -10rem;
}

@media only screen and (max-width: 767px) {
  .app-gallery__item:nth-child(2n) {
    top: 0;
  }
}

.app-gallery__item:nth-child(4n) {
  top: 5rem;
}

@media only screen and (max-width: 767px) {
  .app-gallery__item:nth-child(4n) {
    top: 0;
  }
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}

.tabs {
  background-color: #fff;
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
}

.tabs__nav {
  padding: 1rem;
  background-color: #7190a2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 20;
}

.tabs__link {
  padding: 1rem 2.5rem;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 21px;
  text-align: center;
  opacity: 0.75;
  transition: color 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  background: transparent;
}

.tabs__link.active {
  color: #001c33;
  opacity: 1;
  margin-top: -2rem;
  margin-bottom: -2rem;
  background-color: #51dacd;
  height: 8rem;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .tabs__link.active {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tabs__link:hover {
  opacity: 1;
}

.tabs__content {
  padding-top: 10rem;
}

@media only screen and (max-width: 575px) {
  .tabs__content {
    padding-top: 15rem;
  }
}

.modul-about__title {
  margin-bottom: 5rem;
  color: #001c33;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .modul-about__title {
    margin-bottom: 2rem;
  }
}

.modul-about__columns {
  column-count: 2;
  column-gap: 5rem;
}

@media only screen and (max-width: 575px) {
  .modul-about__columns {
    column-count: 1;
  }
}

.modul-img {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.modul-img--small {
  max-width: 1200px;
}

.modul-features__title {
  color: #001c33;
  margin-bottom: 7.5rem;
}

@media only screen and (max-width: 991px) {
  .modul-features__title {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .modul-features__title {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .modul-features__title {
    margin-bottom: 1rem;
  }
}

.modul-features__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .modul-features__content {
    flex-direction: column;
  }
}

.modul-features__column {
  width: 33.33333%;
  margin-bottom: 3rem;
}

.modul-features__column--four {
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .modul-features__column--four {
    width: 50%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .modul-features__column {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 2rem;
  }
}

.modul-features__column:not(:last-child) {
  padding-right: 2rem;
}

.modul-features__subtitle {
  margin-bottom: 3rem;
  color: #001c33;
}

@media only screen and (max-width: 767px) {
  .modul-features__subtitle {
    margin-bottom: 1rem;
  }
}

.modul-needs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

@media only screen and (max-width: 991px) {
  .modul-needs {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .modul-needs {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .modul-needs {
    font-size: 14px;
    line-height: 16px;
  }
}

.modul-needs__img {
  max-width: 50%;
}

@media only screen and (max-width: 991px) {
  .modul-needs__img {
    margin-bottom: 3rem;
  }
}

.modul-needs__text {
  max-width: 57.5rem;
  margin-left: 10rem;
}

@media only screen and (max-width: 991px) {
  .modul-needs__text {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .modul-needs__text {
    margin-left: 0;
  }
}

.modul-needs__title {
  max-width: 50rem;
  margin-bottom: 5rem;
  color: #001c33;
  font-family: "Inter", sans-serif;
}

@media only screen and (max-width: 767px) {
  .modul-needs__title {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.modul-needs__list {
  width: 100%;
  columns: 2;
}

@media only screen and (max-width: 575px) {
  .modul-needs__list {
    columns: 1;
  }
}

.modul-needs__list li {
  margin-bottom: 2rem;
  padding: 0 25px;
  width: 100%;
  color: #001c33;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .modul-needs__list li {
    margin-left: 20px;
  }
}

.modul-needs__list li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  width: 5px;
  height: 5px;
  background-color: #51dacd;
}

.modul-tools {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .modul-tools {
    flex-direction: column;
  }
}

.modul-tools__text {
  max-width: 78.2rem;
}

.modul-tools__title {
  padding-right: 2rem;
  color: #001c33;
  font-family: "Inter", sans-serif;
}

@media only screen and (max-width: 767px) {
  .modul-tools__title {
    margin-bottom: 2rem;
  }
}

.modul-tools__list {
  margin-top: 5rem;
  width: 100%;
  columns: 2;
}

@media only screen and (max-width: 575px) {
  .modul-tools__list {
    columns: 1;
  }
}

.modul-tools__list li {
  padding: 0 25px;
  width: 100%;
  color: #001c33;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .modul-tools__list li {
    margin-left: 20px;
    font-size: 16px;
    line-height: 20px;
  }
}

.modul-tools__list li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  width: 5px;
  height: 5px;
  background-color: #51dacd;
}

@media only screen and (max-width: 575px) {
  .modul-tools__list li::before {
    top: 8px;
  }
}

.modul-sequence {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;
}

.modul-sequence.mbt0 {
  padding: 5rem 0 5rem 0px;
}

.modul-sequence__title {
  color: #001c33;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 575px) {
  .modul-sequence__title {
    margin-bottom: 2rem;
  }
}

.modul-sequence__subtitle {
  margin-bottom: 2rem;
}

.modul-sequence__content {
  position: relative;
  margin: 0 auto;
  background-color: #51dacd;
}

.modul-sequence__content strong {
  color: #001c33;
}

.modul-sequence__list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10rem 15rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .modul-sequence__list {
    padding: 10rem 10rem;
  }
}

@media only screen and (max-width: 575px) {
  .modul-sequence__list {
    padding: 5rem;
  }
}

.modul-sequence__list li {
  position: relative;
  max-width: 64.5rem;
  padding-left: 14.5rem;
}

@media only screen and (max-width: 575px) {
  .modul-sequence__list li {
    padding-left: 0;
  }
}

.modul-sequence__list li:not(:last-child) {
  margin-bottom: 5rem;
}

.modul-sequence__list li:nth-child(2n) {
  align-self: flex-end;
}

.modul-sequence__number {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: #001c33;
  font-family: "Inter", sans-serif;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 48px;
}

@media only screen and (max-width: 575px) {
  .modul-sequence__number {
    font-size: 4rem;
    letter-spacing: 0;
    line-height: 4rem;
    position: relative;
    padding-bottom: 1rem;
  }
}

.right-tab {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .right-tab {
    flex-direction: column;
  }
}

.right-tab__nav {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .right-tab__nav {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.right-tab__list, .right-tab__content {
  padding-top: 7.5rem;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .right-tab__list, .right-tab__content {
    padding-top: 3rem;
    width: 100%;
  }
}

.right-tab__list {
  padding-right: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .right-tab__list {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .right-tab__nav-item {
    width: 50%;
  }
}

.how-we-work__title-box {
  max-width: 78.2rem;
  margin: 0 auto 1rem;
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
}

.how-we-work__title-box p {
  margin-bottom: 1rem;
}

.how-we-work__title {
  margin-bottom: 5rem;
  color: #001c33;
  font-family: "Inter", sans-serif;
  text-align: center;
}

.greeting-message {
  margin-bottom: 10rem;
}

@media only screen and (max-width: 991px) {
  .greeting-message {
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 575px) {
  .greeting-message {
    margin-bottom: 5rem;
  }
}

.greeting-message__text {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 8rem;
  letter-spacing: 0;
  line-height: 8rem;
  text-align: center;
}

@media (max-width: 1440px) {
  .greeting-message__text {
    font-size: 5.6vw;
    line-height: 5.6vw;
  }
}

.greeting-message img {
  width: 400%;
}

@media only screen and (max-width: 991px) {
  .greeting-message img {
    width: 100%;
  }
}

[data-aos="block-scale"] {
  max-width: 1920px;
  margin-top: -40px;
  transition-property: max-width, margin-top;
}

[data-aos="block-scale"].aos-animate {
  max-width: 1200px;
}

[data-aos="max-width"] {
  max-width: 1920px;
  transition-property: max-width;
}

[data-aos="max-width"].aos-animate {
  max-width: 1200px;
}

[data-aos="full-screen"] {
  max-width: 1200px;
  min-height: 500px;
  transition-property: max-width, min-height;
}

[data-aos="full-screen"].aos-animate {
  max-width: 100vw;
  min-height: 100vh;
}

[data-aos="greeting-zoom"] {
  transform: scale(1);
  margin-bottom: 20rem;
  transition-property: transform, margin-bottom;
}

[data-aos="greeting-zoom"].aos-animate {
  transform: scale(1.3);
  margin-bottom: 10rem;
}

.industries {
  background: #fff;
}

.industries .main__title {
  margin-bottom: 10rem;
}

@media only screen and (max-width: 767px) {
  .industries .main__title {
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 575px) {
  .industries .main__title {
    margin-bottom: 4rem;
  }
}

.industries__list {
  list-style: none;
  margin: 0 -3rem;
}

.industries__item {
  margin: 0 3rem;
}

@media only screen and (min-width: 768px) {
  .industries__item {
    display: flex;
  }
}

.industries__img {
  width: 40%;
  flex-shrink: 0;
  height: 500px;
  opacity: 0;
  transition: opacity 1s ease 0.5s, transform 1s ease 0.5s;
  position: relative;
  z-index: 1;
  transform: translateX(50%);
}

@media only screen and (max-width: 767px) {
  .industries__img {
    transform: translateX(0);
    margin-bottom: 3rem;
    height: 40vw;
    width: 100%;
  }
}

.industries__img img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
  height: 100%;
  width: 100%;
}

.industries__list li.slick-active .industries__img {
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .industries__list li.slick-active .industries__img {
    transform: translateX(0);
  }
}

.industries__text {
  position: relative;
  z-index: 0;
  padding-left: 8rem;
  opacity: 0;
  transition: opacity 1s ease 1.2s, transform 1s ease 1.2s;
  transform: translateX(-50px);
  flex-grow: 1;
}

@media only screen and (max-width: 991px) {
  .industries__text {
    padding-left: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .industries__text {
    transition: opacity 1s ease 0.5s, transform 1s ease 0.5s;
    transform: translateX(0);
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .industries__text p {
    font-size: 14px;
  }
}

.industries__list li.slick-active .industries__text {
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .industries__list li.slick-active .industries__text {
    transform: translateX(0);
  }
}

.industries__title {
  margin-bottom: 4rem;
  color: #001c33;
  font-family: "Inter", sans-serif;
}

@media only screen and (max-width: 767px) {
  .industries__title {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.preview {
  display: flex;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 767px) {
  .preview {
    flex-direction: column;
    margin-bottom: 3rem;
  }
}

.preview__header {
  width: 50%;
  padding-right: 7.5rem;
  flex-shrink: 0;
}

@media only screen and (max-width: 991px) {
  .preview__header {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .preview__header {
    width: 100%;
    padding: 0 0 3rem;
  }
}

.preview__content {
  flex-grow: 1;
  padding-left: 2.5rem;
  color: #525d66;
}

@media only screen and (max-width: 767px) {
  .preview__content {
    width: 100%;
    padding: 0;
  }
}

.preview__title {
  color: #001c33;
}

.preview__link {
  margin-top: 5rem;
}

@media only screen and (max-width: 767px) {
  .preview__link {
    margin-top: 2rem;
  }
}

.services__title {
  margin-bottom: 8rem;
}

@media only screen and (max-width: 767px) {
  .services__title {
    margin-bottom: 3rem;
  }
}

.clients {
  color: #525d66;
}

.clients__title {
  margin-bottom: 5rem;
}

.clients__body {
  padding-right: 0;
}

.clients__mobile-list, .clients__desktop-list {
  margin-top: 5rem;
  width: 100%;
  margin: 5rem -1rem 0;
  display: flex;
}

.clients__mobile-list .column {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
}

.clients__mobile-list .column:nth-child(2) {
  padding-top: 30px;
}

@media only screen and (min-width: 768px) {
  .clients__mobile-list {
    display: none;
  }
}

.clients__desktop-list {
  align-items: center;
}

.clients__desktop-list .column {
  min-height: 351px;
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clients__desktop-list .column:nth-child(1), .clients__desktop-list .column:nth-child(7) {
  padding-top: 105px;
}

.clients__desktop-list .column:nth-child(2), .clients__desktop-list .column:nth-child(6) {
  padding-bottom: 30px;
}

.clients__desktop-list .column:nth-child(3), .clients__desktop-list .column:nth-child(5) {
  padding-top: 118px;
}

@media only screen and (max-width: 767px) {
  .clients__desktop-list {
    display: none;
  }
}

.clients__item {
  height: 100px;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #f4f5f9;
}

@media only screen and (max-width: 991px) {
  .clients__item {
    height: 80px;
    margin: 0.7rem;
    padding: 0.7rem;
  }
}

.clients__logo {
  max-height: 100%;
}

@media only screen and (max-width: 767px) {
  .clients__logo {
    max-width: 80%;
  }
}

.about__title {
  font-size: 144px;
  color: #51dacd;
}

.features .main__title {
  text-align: center;
}

.features__title {
  margin-bottom: 20px;
}

.features__block {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .features__block {
    flex-direction: column;
  }
}

.features__left {
  color: #001c33;
  width: 38%;
  min-width: 35rem;
}

@media only screen and (max-width: 767px) {
  .features__left {
    width: 100%;
  }
}

.features__right {
  color: #525d66;
  width: 62%;
}

@media only screen and (max-width: 767px) {
  .features__right {
    width: 100%;
  }
}

.img-module {
  max-width: 1440px;
  margin: 0 auto 10rem;
  padding-top: 10rem;
  position: relative;
}

.img-module__content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  max-width: 70%;
  padding: 6rem 7rem;
  background-color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 991px) {
  .img-module__content {
    padding: 4rem 4rem;
  }
}

@media only screen and (max-width: 767px) {
  .img-module__content {
    position: relative;
    padding: 5rem 2.5rem 0;
    max-width: 100%;
    background: none;
  }
}

.img-module__text {
  max-width: 59rem;
}

@media only screen and (max-width: 767px) {
  .img-module__text {
    max-width: 100%;
  }
}

.history-module {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: relative;
  padding-bottom: 10rem;
}

.history-module__header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10rem;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .history-module__header {
    padding: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .history-module__header {
    padding: 2rem;
  }
}

.history-module__header-border {
  background-color: #51dacd;
  position: absolute;
  opacity: 0;
}

.history-module__header-border--top {
  left: 0;
  top: 0;
}

.history-module__header-border--bottom1 {
  right: 68%;
  bottom: 0;
}

.history-module__header-border--bottom2 {
  right: 0;
  bottom: 0;
}

.history-module__header-border--left {
  left: 0;
  bottom: 0;
}

.history-module__header-border--right {
  right: 0;
  top: 0;
}

.history-module__header-border--top, .history-module__header-border--bottom1, .history-module__header-border--bottom2 {
  width: 100%;
  height: 2px;
}

.history-module__header-border--left, .history-module__header-border--right {
  height: 100%;
  width: 2px;
}

.history-module__timeline-wrapper {
  position: relative;
  padding-top: 5rem;
}

@media only screen and (max-width: 575px) {
  .history-module__timeline-wrapper {
    padding-top: 2.5rem;
  }
}

.history-module__line {
  width: 2px;
  position: absolute;
  left: 32%;
  top: 0;
  background: #51dacd;
  opacity: 0;
}

.history-module__row {
  display: flex;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 575px) {
  .history-module__row {
    margin-bottom: 2.5rem;
  }
}

.history-module__date {
  padding: 0 10rem 0 2.5rem;
  width: 32%;
  text-align: right;
  color: #7190a2;
  position: relative;
  align-self: start;
}

@media only screen and (max-width: 991px) {
  .history-module__date {
    padding: 0 5rem 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .history-module__date {
    padding: 0 2.5rem 0 0;
  }
}

.history-module__dot {
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translateY(-50%);
  border: 2px solid #51dacd;
  background-color: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.history-module__date-title {
  line-height: normal;
}

@media only screen and (max-width: 991px) {
  .history-module__date-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .history-module__date-title {
    text-align: left;
    font-size: 12px;
  }
}

.history-module__content {
  padding: 0 10rem;
  width: 68%;
  color: #525d66;
}

@media only screen and (max-width: 991px) {
  .history-module__content {
    padding: 0;
    padding-left: 2rem;
  }
}

.history-module__subtitle {
  color: #001c33;
  margin-bottom: 3rem;
}

.team {
  padding: 10rem 0;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .team {
    padding: 5rem 0;
  }
}

@media only screen and (max-width: 575px) {
  .team {
    max-width: 95%;
  }
}

.team__title {
  margin-bottom: 7.5rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .team__title {
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 575px) {
  .team__title {
    margin-bottom: 5rem;
  }
}

.team__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

@media only screen and (max-width: 575px) {
  .team__list {
    margin: 0 -0.5rem;
  }
}

.team__item {
  position: relative;
  margin: 0 1rem 2rem;
  width: calc(20% - 2rem);
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

@media only screen and (max-width: 1365px) {
  .team__item {
    width: calc(25% - 2rem);
  }
}

@media only screen and (max-width: 991px) {
  .team__item {
    width: calc(33.33% - 2rem);
  }
}

@media only screen and (max-width: 767px) {
  .team__item {
    width: calc(50% - 2rem);
  }
}

@media only screen and (max-width: 575px) {
  .team__item {
    margin: 0 0.5rem 1rem;
    width: calc(50% - 1rem);
  }
}

.team__info-title {
  background: rgba(23, 23, 23, 0.8);
  bottom: 10%;
  color: #fff;
  font-weight: 700;
  left: 0;
  letter-spacing: -1px;
  padding: 9px 11px;
  position: absolute;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  z-index: 2;
  max-width: 90%;
}

@media only screen and (max-width: 991px) {
  .team__info-title {
    padding: 5px 7px;
  }
}

.team__img-wrapper {
  border-radius: 3px;
  height: 35rem;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .team__img-wrapper {
    height: 25rem;
  }
}

.team__img-wrapper img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  font-family: "object-fit: cover; object-position: top";
}

.team__name {
  font-size: 2rem;
  line-height: 2.8rem;
}

@media only screen and (max-width: 991px) {
  .team__name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .team__name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .team__name {
    font-size: 12px;
  }
}

.team__position {
  background-color: #1a87e4;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  float: left;
  font-weight: 400;
  letter-spacing: 0;
  margin: 8px -2px -15px -2px;
  padding: 2px 9px;
  text-transform: none;
  z-index: 2;
}

@media only screen and (max-width: 991px) {
  .team__position {
    font-size: 10px;
  }
}

.team__description {
  padding: 10px;
  display: block;
}

@media only screen and (max-width: 575px) {
  .team__description {
    padding: 10px 0;
  }
}

.team__description p {
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .team__description p {
    font-size: 10px;
  }
}

.social-module {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .social-module {
    flex-direction: column;
  }
}

.social-module__title {
  width: 47%;
}

@media only screen and (max-width: 991px) {
  .social-module__title {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
  }
}

.social-module .social__list {
  width: 48%;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .social-module .social__list {
    width: 100%;
  }
}

.social-module .social__list li:first-child .social__link {
  margin-left: 0;
}

.social-module .social__link {
  height: 4.8rem;
  width: 4.8rem;
  margin-left: 8rem;
}

@media only screen and (max-width: 767px) {
  .social-module .social__link {
    margin-left: 4rem;
  }
}

.social-module .social__link i {
  font-size: 4.8rem;
}

@media only screen and (max-width: 1365px) {
  .career-details__title {
    font-size: 4.5rem;
    line-height: normal;
  }
}

@media only screen and (max-width: 991px) {
  .career-details__title {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media only screen and (max-width: 575px) {
  .career-details__title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.career-details__block {
  display: flex;
}

.career-details__block:not(:last-child) {
  margin-bottom: 11rem;
}

@media only screen and (max-width: 767px) {
  .career-details__block:not(:last-child) {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .career-details__block:not(:last-child) {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .career-details__block {
    flex-direction: column;
  }
}

.career-details__left {
  padding-right: 7.5rem;
  width: 50%;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 767px) {
  .career-details__left {
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.career-details__content {
  padding-left: 2.5rem;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .career-details__content {
    padding: 0;
    width: 100%;
  }
}

.small-gallery {
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}

.small-gallery__list {
  display: flex;
  width: 110%;
  margin-left: -5%;
}

.small-gallery__list:not(:last-child) {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 767px) {
  .small-gallery__list:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .small-gallery__list:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.small-gallery__item:not(:last-child) {
  margin-right: 3rem;
}

@media only screen and (max-width: 767px) {
  .small-gallery__item:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .small-gallery__item:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.block-list {
  padding: 8.7rem 0;
  background-color: #f4f5f9;
}

@media only screen and (max-width: 575px) {
  .block-list {
    padding: 1.2rem 0;
  }
}

.block-list__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.3rem;
}

.block-list__item {
  padding: 1.3rem;
  flex: 0 0 33.3%;
}

@media only screen and (max-width: 767px) {
  .block-list__item {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 575px) {
  .block-list__item {
    flex: 0 0 100%;
  }
}

.block-list__link {
  display: block;
  padding: 3rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.block-list__link:hover {
  transform: scale(0.95);
}

.block-list__name {
  margin-bottom: 1rem;
  color: #1a87e4;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 21px;
}

.block-list__role {
  color: #7190a2;
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.offer {
  color: #525d66;
}

.offer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .offer__list {
    padding: 2.6rem 0;
  }
}

.offer__item {
  margin-top: 10rem;
  flex: 0 0 30.2%;
}

@media only screen and (max-width: 767px) {
  .offer__item {
    flex: 0 0 47%;
  }
}

@media only screen and (max-width: 575px) {
  .offer__item {
    margin-top: 3rem;
    flex: 0 0 100%;
  }
}

.offer__subtitle {
  margin-bottom: 3rem;
  color: #001c33;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}

@media only screen and (max-width: 575px) {
  .offer__subtitle {
    margin-bottom: 1rem;
  }
}

.bullet-list__list li {
  margin-bottom: 2rem;
  padding: 0 25px;
  width: 100%;
  color: #525d66;
  font-family: "Nunito Sans", sans-serif;
  line-height: 28px;
  position: relative;
}

.bullet-list__list li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #51dacd;
}

.specialist {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 10rem 2.5rem;
  background-color: #f4f5f9;
}

@media only screen and (max-width: 767px) {
  .specialist {
    padding: 5rem 2.5rem;
  }
}

.specialist__block {
  max-width: 782px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .specialist__block {
    flex-direction: column;
  }
}

.specialist__photo {
  margin-right: 5rem;
  width: 20rem;
  height: 20rem;
  flex-shrink: 0;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .specialist__photo {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.specialist__role {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 575px) {
  .specialist__role {
    text-align: center;
  }
}

.specialist__title {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 575px) {
  .specialist__title {
    text-align: center;
  }
}

.specialist__contacts {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 575px) {
  .specialist__contacts {
    flex-direction: column;
    text-align: center;
  }
}

.specialist__contact:not(:last-child) {
  margin-right: 5rem;
}

@media only screen and (max-width: 575px) {
  .specialist__contact:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.location {
  color: #525d66;
}

.location__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5rem;
  margin-left: -5rem;
}

@media only screen and (max-width: 575px) {
  .location__items {
    margin-top: 0;
    margin-left: 0;
  }
}

.location__item {
  flex: 1 0 calc(50% - 5rem);
  margin-left: 5rem;
  margin-top: 5rem;
}

@media only screen and (max-width: 575px) {
  .location__item {
    flex: 1 0 100%;
    margin-left: 0;
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .location__item :not(:first-child) {
    margin-bottom: 3rem;
  }
}

.location__subtitle {
  margin-bottom: 10px;
  color: #001c33;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  contain: layout style size;
  pointer-events: none;
  will-change: transform;
  transition: opacity 0.3s, color 0.4s;
}

.cursor::before {
  content: "";
  position: absolute;
  top: -24px;
  left: -24px;
  display: block;
  width: 48px;
  height: 48px;
  transform: scale(0);
  background: currentColor;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out, opacity 0.1s;
}

.cursor__text {
  position: absolute;
  top: -18px;
  left: -18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0) rotate(10deg);
  opacity: 0;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  transition: opacity 0.4s, transform 0.3s;
}

.cursor.--visible::before {
  transform: scale(0.2);
}

.cursor.--pointer::before {
  transform: scale(0.15);
}

.cursor.--visible.--active::before {
  transform: scale(0.3);
  transition-duration: 0.2s;
}

@supports (mix-blend-mode: exclusion) {
  .cursor.--exclusion {
    mix-blend-mode: exclusion;
  }
}

@supports (mix-blend-mode: exclusion) {
  .cursor.--exclusion::before {
    background: #fff;
  }
}

@supports (mix-blend-mode: exclusion) {
  .cursor {
    mix-blend-mode: exclusion;
  }
}

@supports (mix-blend-mode: exclusion) {
  .cursor::before {
    background: #fff;
  }
}

.cursor.--text, .cursor.--text::before {
  mix-blend-mode: normal;
  background: currentColor;
}

.cursor.--text .cursor__text {
  opacity: 1;
  transform: scale(1);
}

.cursor.--text::before {
  opacity: 0.85;
  transform: scale(1.7);
}

.cursor.--text.--active::before {
  transform: scale(1.6);
  transition-duration: 0.2s;
}

.cursor.--exclusion::before {
  transform: scale(1.32);
}

.cursor.--exclusion.--active::before {
  transform: scale(1.2);
}

.cursor.--hidden::before {
  transform: scale(0);
}

[data-slider-hover-prev],
[data-slider-hover-next] {
  cursor: pointer;
}

@keyframes link-underline {
  50% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.menu__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.menu--open .menu__backdrop {
  pointer-events: auto;
}

.menu__box {
  display: none;
}

.menu__content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 50px 30px 90px 45px;
  background: #fff;
}

@media (min-height: 550px) {
  .menu__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 0 45px;
  }
}

@media (min-width: 768px) {
  .menu__content {
    width: auto;
    left: auto;
    padding: 0 98px;
  }
}

@media (min-width: 768px) and (min-height: 550px) {
  .menu__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 98px 0;
  }
}

@media (min-width: 768px) and (min-height: 600px) {
  .menu__content {
    padding: 40px 98px 0;
  }
}

@media (min-width: 1600px) and (min-height: 700px) {
  .menu__content {
    padding: 50px 98px 0;
  }
}

.menu__body {
  width: 100%;
  padding: 5rem 0;
}

@media (min-width: 768px) and (min-height: 550px) {
  .menu__body {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.menu__footer {
  padding: 20px 0 30px 0;
}

@media (min-width: 768px) and (min-height: 550px) {
  .menu__footer {
    padding: 0 0 50px 0;
  }
}

@media (min-width: 1600px) and (min-height: 700px) {
  .menu__footer {
    padding: 0 0 84px 0;
  }
}

.menu__title {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  line-height: 100%;
  display: block;
  font-weight: normal;
}

.menu__mail {
  display: inline-block;
  padding: 12px 0 5px 0;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: #000;
}

.menu__mail::after, .menu__mail::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}

.menu__mail::after {
  background: #000;
  transform: scaleX(0);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu__mail:hover::after {
  transform: scaleX(1);
}

.menu__list {
  margin: -4px 0;
  padding: 30px 0 0 0;
}

@media (min-width: 1600px) and (min-height: 700px) {
  .menu__list {
    margin: -9px 0;
    padding: 60px 0 0 0;
  }
}

.menu__list li {
  position: relative;
  display: block;
  margin: 4px 0;
}

@media (min-width: 1600px) and (min-height: 700px) {
  .menu__list li {
    margin: 9px 0;
  }
}

.menu__list li.active a::before {
  transform: scaleX(1);
  transform-origin: left center;
}

.menu__link {
  display: inline-block;
  position: relative;
  font-weight: 400;
  letter-spacing: -0.05em;
  font-size: 34px;
  line-height: 1.23;
  text-decoration: none;
}

@media (min-width: 360px) {
  .menu__link {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (min-height: 550px) {
  .menu__link {
    font-size: 45px;
  }
}

@media (max-height: 550px) {
  .menu__link {
    font-size: 28px;
  }
}

@media (max-height: 450px) {
  .menu__link {
    font-size: 20px;
  }
}

.menu__link::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 1px;
  transform: scaleX(0);
  transform-origin: right center;
  background: currentColor;
  transition: transform 0.5s, transform-origin 0s;
}

@media (min-width: 768px) {
  .menu__link {
    bottom: -7px;
  }
}

.menu__link em {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 0 0.01em 0 0;
  font-style: normal;
}

.menu__link span {
  display: inline-block;
  color: transparent;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu__link span::after,
.menu__link span::before {
  content: attr(data-text);
  display: block;
  position: absolute;
  color: #000;
}

.menu__link span::before {
  top: 0;
  transform: skewY(0);
  transform-origin: right bottom;
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu__link span::after {
  top: 105%;
  transform: skewY(7deg);
  transform-origin: left top;
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu__link:focus span,
.menu__link:hover span {
  transform: translateY(-105%);
}

@media (pointer: coarse) {
  .menu__link:focus span,
  .menu__link:hover span {
    transform: none;
  }
}

.menu__link:focus span::before,
.menu__link:hover span::before {
  transform: skewY(7deg);
}

@media (pointer: coarse) {
  .menu__link:focus span::before,
  .menu__link:hover span::before {
    transform: none;
  }
}

.menu__link:focus span::after,
.menu__link:hover span::after {
  transform: skewY(0);
}

@media (pointer: coarse) {
  .menu__link:focus span::after,
  .menu__link:hover span::after {
    transform: none;
  }
}

.menu-toggle {
  position: fixed;
  top: 29px;
  right: 20px;
  z-index: 151;
}

@media (min-width: 768px) and (min-height: 550px) {
  .menu-toggle {
    top: 44px;
    right: 30px;
  }
}

@media (min-width: 1200px) and (min-height: 700px) {
  .menu-toggle {
    top: 73px;
    right: 60px;
  }
}

.menu-btn {
  position: relative;
  display: inline-block;
  height: auto;
  margin: 0;
  padding: 0;
  color: inherit;
  background: 0 0;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
  text-indent: inherit;
  text-decoration: none;
  height: 25px;
}

.menu-btn:focus, .menu-btn:hover {
  text-decoration: none;
}

.menu-btn__title {
  display: none;
}

@media (min-width: 768px) {
  .menu-btn__title {
    display: inline-block;
    margin: 0 25px 0 0;
    font-size: 20px;
    letter-spacing: -0.03em;
  }
}

.menu-btn__box {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 25px;
  line-height: 25px;
  vertical-align: middle;
  transition: color 0.2s;
}

.menu-btn__box span {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  top: 0;
  z-index: 1;
  backface-visibility: hidden;
  background: currentColor;
  transition: top 0.3s 0.3s, transform 0.3s, background-color 0.3s;
}

.menu-btn__box:hover .menu-btn__box__box {
  color: #fff;
  transition-delay: 0.15s;
}

@media (min-width: 1024px) {
  .menu-btn__box:hover .menu-btn__box__box:before {
    top: -80px;
    right: -80px;
    left: -80px;
    bottom: -80px;
  }
}

.menu-btn__box::before {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  right: -20px;
  left: -20px;
  bottom: -20px;
}

.nav-bar--inverse .menu-btn,
.menu--inverse .menu-btn {
  color: #fff;
}

.menu--open .menu-btn {
  color: #000;
}

.menu--open .menu-btn__box span:first-child {
  animation: btn_menu-open-top 0.9s;
  top: 4px;
  transform: rotate(45deg) scaleX(1);
  transition-duration: 0s, 0s;
}

.menu--open .menu-btn__box span:last-child {
  animation: btn_menu-open-bottom 0.9s;
  top: -3px;
  transform: rotate(-45deg) scaleX(1);
  transition-duration: 0s, 0s;
}

@keyframes btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: left center;
  }
  30% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    transform: rotate(45deg) scaleX(0);
  }
  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    transform: rotate(45deg) scaleX(1);
    transform-origin: left center;
  }
}

@keyframes btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: right center;
  }
  50% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }
  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    transform: rotate(-45deg) scaleX(0);
  }
  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    transform: rotate(-45deg) scaleX(1);
    transform-origin: right center;
  }
}

.nav-bar {
  position: absolute;
  top: 25px;
  left: 20px;
  right: 20px;
  z-index: 99;
}

@media only screen and (min-height: 550px) and (min-width: 768px) {
  .nav-bar {
    top: 40px;
  }
}

@media (min-height: 700px) and (min-width: 1200px) {
  .nav-bar {
    top: 60px;
    left: 60px;
    right: 60px;
  }
}

.logo--menu {
  position: fixed;
  top: 25px;
  left: 20px;
  z-index: 151;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.8s;
}

@media (min-width: 768px) and (min-height: 550px) {
  .logo--menu {
    top: 40px;
  }
}

@media (min-width: 1200px) and (min-height: 700px) {
  .logo--menu {
    top: 60px;
    left: 60px;
  }
}

.logo img {
  width: 50px;
  margin-top: -10px;
}

@media (min-width: 768px) and (min-height: 550px) {
  .logo img {
    width: 70px;
    margin-top: -10px;
  }
}

@media (min-height: 700px) and (min-width: 1366px) {
  .logo img {
    width: 80px;
    margin-top: -20px;
  }
}

.menu--open .logo {
  opacity: 1;
  pointer-events: auto;
}

.parallax {
  perspective: 100px;
  overflow: hidden;
  position: relative;
}

.parallax img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
  min-height: 120%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.filter__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

@media only screen and (min-width: 768px) {
  .filter__list {
    margin: 0 -9px;
  }
}

.filter__list > * {
  margin: 7px 6px;
}

@media only screen and (min-width: 768px) {
  .filter__list > * {
    margin: 12px 9px;
  }
}

.cta-ripple {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  height: 49px;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: -0.01em;
  background: #fff;
  border-radius: 68px;
  user-select: none;
  transition: transform 0.2s ease;
  color: #001c33;
}

.cta-ripple:hover {
  transform: translateY(-3px);
}

@media only screen and (min-width: 768px) {
  .cta-ripple {
    padding: 0 32px;
    height: 65px;
    font-size: 18px;
    border-radius: 90px;
  }
}

.cta-ripple::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: inherit;
  transition: border-color 0.2s;
}

.cta-ripple__view {
  position: relative;
  top: -1px;
  display: block;
  overflow: hidden;
}

.cta-ripple__title {
  display: block;
  position: relative;
  z-index: 2;
  transition: color 0.2s, transform 0.4s;
  transform-origin: left top;
}

.cta-ripple__title::after {
  content: attr(data-text);
  display: block;
  position: absolute;
  top: 150%;
  left: 0;
  transform: skewY(5deg);
  transform-origin: left top;
  transition: transform 0.4s;
}

.cta-ripple:hover .cta-ripple__title {
  transform: translateY(-150%) skewY(-7deg);
}

.cta-ripple:hover .cta-ripple__title::after {
  transform: skewY(7deg);
}

.cta-ripple.active .cta-ripple__title {
  color: #fff;
}

.cta-ripple__ripple {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  contain: strict;
  border-radius: inherit;
}

.cta-ripple__ripple span {
  display: block;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  background: currentColor;
  border-radius: 50%;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0, 1), border-radius 0.5s cubic-bezier(0.4, 0, 0, 1);
}

.cta-ripple.active .cta-ripple__ripple {
  mask-image: radial-gradient(circle, #fff 100%, #000 100%);
}

.cta-ripple.active .cta-ripple__ripple span {
  border-radius: 0;
  transform: translateY(0);
  transition-duration: 0s, 0s;
  animation: ripple-in 0.5s cubic-bezier(0.4, 0, 0, 1);
}

@keyframes ripple-in {
  0% {
    border-radius: 100%;
    transform: translateY(-100%);
  }
  100% {
    border-radius: 0;
    transform: translateY(0);
  }
}

.img--big, .hero__img, .modul-img, .img-module__bgwrapper {
  height: 60vw;
}

@media (min-width: 450px) {
  .img--big, .hero__img, .modul-img, .img-module__bgwrapper {
    height: 50vw;
  }
}

@media only screen and (min-width: 768px) {
  .img--big, .hero__img, .modul-img, .img-module__bgwrapper {
    height: 400px;
  }
}

@media only screen and (min-width: 992px) {
  .img--big, .hero__img, .modul-img, .img-module__bgwrapper {
    height: 500px;
  }
}

@media only screen and (min-width: 1366px) {
  .img--big, .hero__img, .modul-img, .img-module__bgwrapper {
    height: 600px;
  }
}
