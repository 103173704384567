.clients {
  color: $tc2;

  &__title {
    margin-bottom: 5rem;
  }

  &__body {
    padding-right: 0;
  }

  &__mobile-list,
  &__desktop-list {
    margin-top: 5rem;
    width: 100%;
    margin: 5rem -1rem 0;
    display: flex;
  }

  &__mobile-list {
    .column {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        padding-top: 30px;
      }
    }
    @include md {
      display: none;
    }
  }

  &__desktop-list {
    align-items: center;

    .column {
      min-height: 351px;
      width: calc(100% / 7);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:nth-child(1),
      &:nth-child(7) {
        padding-top: 105px;
      }
      &:nth-child(2),
      &:nth-child(6) {
        padding-bottom: 30px;
      }
      &:nth-child(3),
      &:nth-child(5) {
        padding-top: 118px;
      }
    }
    @include md-max {
      display: none;
    }
  }

  &__item {
    height: 100px;
    margin: 1rem;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;
    background-color: $gray;

    @include lg-max {
      height: 80px;
      margin: 0.7rem;
      padding: 0.7rem;
    }

    // @include md-max {
    //   width: 100px;
    //   height: 100px;
    // }
  }

  &__logo {
    max-height: 100%;

    @include md-max {
      max-width: 80%;
    }
  }
}
