.hero + .app-details {
  margin-top: -3rem;
}

.app-details {
  border-bottom: 1px solid #e6e9f2;

  &__list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @include md {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  &__item {
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 0 1rem 2rem;

    @include rwd(400) {
      width: 50%;
    }

    @include md {
      width: auto;
      padding: 0 1rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
    color: #7190a2;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 24px;

    @include lg-max {
      font-size: 16px;
    }

    @include md-max {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
  }

  &__text {
    color: $tc1;
    font-family: $ff-title;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    max-width: 300px;
    line-height: 1.2;

    @include lg-max {
      font-size: 18px;
    }

    @include md-max {
      font-size: 16px;
    }
  }
}
