.parallax {
  perspective: 100px;
  overflow: hidden;
  position: relative;

  img {
    @include object-fit(cover, center);
    min-height: 120%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}