.specialist {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 10rem 2.5rem;
  background-color: $gray;

  @include md-max {
    padding: 5rem 2.5rem;
  }

  &__block {
    max-width: 782px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include sm-max {
      flex-direction: column;
    }
  }

  &__photo {
    margin-right: 5rem;
    width: 20rem;
    height: 20rem;
    flex-shrink: 0;
    overflow: hidden;
    @include sm-max {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__role {
    margin-bottom: 1rem;

    @include sm-max {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 3rem;

    @include sm-max {
      text-align: center;
    }
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;

    @include sm-max {
      flex-direction: column;
      text-align: center;
    }
  }
  &__contact:not(:last-child) {
    margin-right: 5rem;
    @include sm-max {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}
