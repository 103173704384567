.team {
  padding: 10rem 0;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include md-max {
    padding: 5rem 0;
  }

  @include sm-max {
    max-width: 95%;
  }

  &__title {
    margin-bottom: 7.5rem;
    text-transform: uppercase;

    @include lg-max {
      margin-bottom: 6rem;
    }

    @include sm-max {
      margin-bottom: 5rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @include sm-max {
      margin: 0 -0.5rem;
    }
  }

  &__item {
    position: relative;
    margin: 0 1rem 2rem;
    width: calc(20% - 2rem);
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 3px;

    @include xl-max {
      width: calc(25% - 2rem);
    }

    @include lg-max {
      width: calc(33.33% - 2rem);
    }

    @include md-max {
      width: calc(50% - 2rem);
    }

    @include sm-max {
      margin: 0 0.5rem 1rem;
      width: calc(50% - 1rem);
    }
  }

  &__info-title {
    background: rgba(23, 23, 23, 0.8);
    bottom: 10%;
    color: #fff;
    font-weight: 700;
    left: 0;
    letter-spacing: -1px;
    padding: 9px 11px;
    position: absolute;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    z-index: 2;
    max-width: 90%;

    @include lg-max {
      padding: 5px 7px;
    }

    // @include sm-max {
    //   bottom: 0;
    //   transform: translateY(100%);
    // }
  }

  &__img-wrapper {
    border-radius: 3px;
    height: 35rem;
    position: relative;

    @include sm-max {
      height: 25rem;
    }

    img {
      height: 100%;
      width: 100%;

      @include object-fit(cover, top);
    }
  }

  &__name {
    font-size: 2rem;
    line-height: 2.8rem;

    @include lg-max {
      font-size: 16px;
    }

    @include md-max {
      font-size: 14px;
    }

    @include sm-max {
      font-size: 12px;
    }
  }

  &__position {
    background-color: $accent2;
    border-radius: 2px;
    display: inline-block;
    font-size: 12px;
    float: left;
    font-weight: 400;
    letter-spacing: 0;
    margin: 8px -2px -15px -2px;
    padding: 2px 9px;
    text-transform: none;
    z-index: 2;

    @include lg-max {
      font-size: 10px;
    }
  }

  &__description {
    padding: 10px;
    display: block;

    @include sm-max {
      padding: 10px 0;
    }

    p {
      font-size: 14px;

      @include sm-max {
        font-size: 10px;
      }
    }
  }
}
