.quote-block {
  background-color: $accent2;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #ffffff;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 28px;
  }

  .container {
    display: flex;

    @include md-max {
      flex-direction: column-reverse;
    }
  }

  &__title {
    max-width: 50rem;
    padding-right: 3rem;
    margin-bottom: 2rem;
    color: #ffffff;
    font-family: $ff-title;
    font-size: 4.8rem;
    letter-spacing: 0;
    line-height: 4.8rem;

    @include md-max {
      max-width: 100%;
      padding-right: 0;
      padding-left: 60px;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    @include sm-max {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  &__left {
    flex: 1 1 50%;
    position: relative;
    z-index: 2;

    @include md-max {
      text-align: right;
    }
  }

  &__right {
    flex: 1 1 50%;
    position: relative;

    @include md-max {
      padding-left: 60px;
    }

    @include sm-max {
      text-align: center;
      padding-left: 0;
    }
  }

  &__img {
    padding-right: 5.5rem;
    width: 60%;
    position: absolute;
    top: -3rem;
    left: -52%;
    z-index: 1;

    @include md-max {
      padding-right: 0;
      width: 80px;
      top: -2rem;
      left: 0;
    }

    @include sm-max {
      width: 80px;
      top: -3rem;
      left: -15px;
    }
  }
}
