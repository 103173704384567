/*=== containers ===*/
.container-base {
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 768px) and (min-height: 550px) {
    padding: 0 30px;
  }

  @media (min-width: 1200px) and (min-height: 700px) {
    padding: 0 60px;
  }
}

.container-max {
  max-width: 1440px;

  @extend .container-base;
}

.container-md {
  max-width: 1250px;
  @extend .container-base;
}

.container {
  max-width: 1074px;
  @extend .container-base;
}

.container-s {
  max-width: 975px;
  @extend .container-base;
}

.container-xs {
  max-width: 832px;
  @extend .container-base;
}

/*=== block scroll ===*/
body.lock {
  overflow: hidden;
}

/*=== darken image ===*/
.darkened {
  display: block;
  position: relative;
}

.darkened::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: all 0.7s ease;
}

/*=== buttons ===*/

.btn {
  display: block;
  color: $accent1;
  font-family: $ff-text;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 18px;
  background-color: transparent;
  transition: all 0.3s ease;
  position: relative;

  &--center {
    text-align: center;
  }

  &:hover {
    text-decoration: underline;
  }
}

.btn-rectangle {
  min-width: 226px;
  padding: 23px;
  border-radius: 30px;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  font-family: $ff-text;
  background-color: $accent2;
  transition: all 0.3s ease;
  position: relative;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
  }
}

/*=== textaera autoheight ===*/
.txta {
  width: 100%;
  max-width: 100%;
  min-height: 30px;
  background-color: transparent;
  font-family: $ff-text;
  overflow: hidden;
  border-bottom: solid 1px rgba(47, 55, 79, 0.1);
  line-height: 3rem;
  font-size: 24px;
}

/*=== block global padding ===*/
.pb10 {
  padding-bottom: 10rem;
  @include lg-max {
    padding-bottom: 8rem;
  }
  @include md-max {
    padding-bottom: 5rem;
  }
  @include sm-max {
    padding-bottom: 3rem;
  }
}

.pt5 {
  padding-top: 5rem;
}

.pb5 {
  padding-bottom: 5rem;
}

.mb5 {
  margin-bottom: 5rem;

  @include md-max {
    // margin-bottom: 3rem;
  }
}

.pt10 {
  padding-top: 10rem;
  @include xl-max {
    padding-top: 8rem;
  }
  @include md-max {
    padding-top: 5rem;
  }
  @include sm-max {
    padding-top: 3rem;
  }
}

.pt20 {
  padding-top: 20rem;
  @include xl-max {
    padding-top: 15rem;
  }
  @include md-max {
    padding-top: 10rem;
  }
  @include sm-max {
    padding-top: 5rem;
  }
}

.mt10 {
  margin-top: 10rem;
  @include xl-max {
    margin-top: 8rem;
  }
  @include md-max {
    margin-top: 5rem;
  }
  @include sm-max {
    margin-top: 3rem;
  }
}

.mt20 {
  margin-top: 10rem;
  @include xl-max {
    margin-top: 15rem;
  }
  @include md-max {
    margin-top: 10rem;
  }
  @include sm-max {
    margin-top: 5rem;
  }
}

.mb20 {
  margin-bottom: 20rem;

  @include lg-max {
    margin-bottom: 15rem;
  }

  @include md-max {
    margin-bottom: 10rem;
  }

  @include sm-max {
    margin-bottom: 5rem;
  }
}

.mb10 {
  margin-bottom: 10rem;

  @include xl-max {
    margin-bottom: 8rem;
  }

  @include md-max {
    margin-bottom: 5rem;
  }

  @include sm-max {
    margin-bottom: 3rem;
  }
}

.img-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    /*width: 100%;
    height: 100%;*/
  }
}

.img-title {
  padding-left: 5rem;
  padding-right: 5rem;
}

.light-text {
  color: $tc3;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 21px;

  @include sm-max {
    font-size: 14px;
    line-height: 16px;
  }
}

.text-link {
  color: $tc1;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  transition: all 0.3 ease-in-out;

  @include sm-max {
    font-size: 14px;
    line-height: 16px;
  }

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.modul-about ul li,
.modul-features ul li,
.modul-needs ul li,
.how-we-work__title-box ul li,
.features__block ul li,
.text-block ul li,
.img-module__content ul li,
.history-module__content ul li,
.app-about ul li {
  padding: 0 25px;
  width: 100%;
  color: $tc2;
  font-weight: normal;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;
  position: relative;

  @include sm-max {
    margin-left: 20px;
    font-size: 14px;
    line-height: 16px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $accent1;

    @include sm-max {
      top: 6px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.modul-about ul,
.modul-features ul,
.modul-needs ul,
.how-we-work__title-box ul,
.features__block ul,
.text-block ul,
.img-module__content ul,
.history-module__content ul,
.app-about ul {
  margin: 2rem 1rem 2rem 0rem;

  @include sm-max {
    margin: 2rem 0 1rem;
  }
}
