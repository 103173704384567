.industries {
  background: #fff;

  .main__title {
    margin-bottom: 10rem;

    @include md-max {
      margin-bottom: 6rem;
    }

    @include sm-max {
      margin-bottom: 4rem;
    }
  }

  &__list {
    list-style: none;
    margin: 0 -3rem;
  }

  &__item {
    margin: 0 3rem;

    @include md {
      display: flex;
    }
  }

  &__img {
    width: 40%;
    flex-shrink: 0;
    height: 500px;
    opacity: 0;
    transition: opacity 1s ease 0.5s, transform 1s ease 0.5s;
    position: relative;
    z-index: 1;
    transform: translateX(50%);
    
    @include md-max {
      transform: translateX(0);
      margin-bottom: 3rem;
      height: 40vw;
      width: 100%;
    }

    img {
      @include object-fit(cover, center);
      height: 100%;
      width: 100%;
    }
  }

  &__list li.slick-active &__img {
    opacity: 1;

    @include md {
      transform: translateX(0);
    }
  }

  &__text {
    position: relative;
    z-index: 0;
    padding-left: 8rem;
    opacity: 0;
    transition: opacity 1s ease 1.2s, transform 1s ease 1.2s;
    transform: translateX(-50px);
    flex-grow: 1;
    
    @include lg-max {
      padding-left: 5rem;
    }
    
    @include md-max {
      transition: opacity 1s ease 0.5s, transform 1s ease 0.5s;
      transform: translateX(0);
      padding-left: 0;
    }

    p {
      @include lg-max {
        font-size: 14px;
      }
    }
  }

  &__list li.slick-active &__text {
    opacity: 1;

    @include md {
      transform: translateX(0);
    }
  }

  &__title {
    margin-bottom: 4rem;
    color: $tc1;
    font-family: $ff-title;

    @include md-max {
      max-width: 100%;
      margin-bottom: 2rem;
    }
  }
}
