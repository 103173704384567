.error-page {
  min-height: calc(100vh - 300px);
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  display: flex;
  align-items: center;

  @include lg-max {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @include sm-max {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }

  .container-max {
    width: 100%;
    min-height: 24.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% center;

    @include rwd-max(1440) {
      min-height: 19.4vw;
      background-size: 63%;
      background-position: right center;
    }
    @include md-max {
      padding-top: 30%;
      background-position: top center;
      background-size: 70%;
    }

    .main__title {
      margin-bottom: 3rem;

      @include xl-max {
        font-size: 60px;
        line-height: 60px;
      }

      @include sm-max {
        margin-bottom: 1rem;
      }
    }
  }

  &__text {
  max-width: 33%;
  color: $tc2;
  font-family: $ff-text;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 28px;

  @include md-max {
    max-width: 100%;
    text-align: center;
}



  }

  &__btn {
    margin-top: 5rem;
    display: flex;
    align-items: center;

    @include md-max {
        justify-content: center;
      }
  }
}
