h1,
h2 {
  color: $accent1;
  font-family: $ff-title;
  font-weight: 500;
  text-transform: uppercase;
  user-select: none;
}

h3,
h4,
h5,
h6 {
  font-family: $ff-title;
  font-weight: 400;
}

h1 {
  font-size: 4rem;
  line-height: 1.2;

  @include sm {
    font-size: 6rem;
  }

  @include xl {
    font-size: 8rem;
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.2;

  @include sm {
    font-size: 4rem;
  }

  @include xl {
    font-size: 6rem;
  }
}

h3 {
  font-size: 3rem;
  line-height: 1;
  text-transform: uppercase;

  @include sm-max {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

h4 {
  font-size: 2.8rem;
  line-height: 3rem;

  @include sm-max {
    font-size: 2.2rem;
    line-height: 1.3;
  }
}

h5 {
  font-size: 2.4rem;
  line-height: 2.6rem;

  @include sm-max {
    font-size: 2rem;
  }
}

p {
  font-size: 18px;
  line-height: 1.5;

  @include lg-max {
    font-size: 16px;
  }

  @include sm-max {
    font-size: 14px;
  }
}
