.features {
    .main__title {
        text-align: center;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__block {
        display: flex;

        @include md-max {
            flex-direction: column;
        }
    }

    &__left {
        color: $tc1;
        width: 38%;
        min-width: 35rem;
        @include md-max {
            width: 100%;
        }
    }

    &__right {
        color: $tc2;
        width: 62%;
        @include md-max {
            width: 100%;
        }
    }
}