.location {
    color: $tc2;
    &__items{
        display: flex;
        flex-wrap: wrap;
        margin-top: -5rem;
        margin-left: -5rem;

        @include sm-max {
            margin-top: 0;
            margin-left: 0;
        }
    }

    &__item {
        flex: 1 0 calc(50% - 5rem);
        margin-left: 5rem;
        margin-top: 5rem;

        @include sm-max {
            flex: 1 0 100%;
            margin-left: 0;
            margin-top: 0;
        }

        :not(:first-child){
            @include sm-max {
                margin-bottom: 3rem;
            }
        }
    }

    &__subtitle {
        margin-bottom: 10px;
        color: $tc1;
    }
}