.bullet-list {
    &__list {
        li {
            margin-bottom: 2rem;
            padding: 0 25px;
            width: 100%;
            color: $tc2;
            font-family: $ff-text;
            line-height: 28px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 9px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $accent1;
            }
        }
    }
}
