.img-module {
  max-width: 1440px;
  margin: 0 auto 10rem;
  padding-top: 10rem;
  position: relative;

  &__bgwrapper {
    @extend .img--big;
  }

  &__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    max-width: 70%;
    padding: 6rem 7rem;
    background-color: rgba($white, 0.7);

    @include lg-max {
      padding: 4rem 4rem;
    }

    @include md-max {
      position: relative;
      padding: 5rem 2.5rem 0;
      max-width: 100%;
      background: none;
    }
  }

  &__text {
    max-width: 59rem;

    @include md-max {
      max-width: 100%;
    }
  }
}
