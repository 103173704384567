.text-block {
  &__title {
    margin-bottom: 5rem;

    @include sm-max {
      margin-bottom: 3rem;
    }

    &--image {
      margin-bottom: 20rem;

      @include lg-max {
        margin-bottom: 15rem;
      }
    
      @include md-max {
        margin-bottom: 10rem;
      }
    
      @include sm-max {
        margin-bottom: 5rem;
      }
    }
  }
  
  &__text {
    color: $tc2;
    font-family: $ff-text;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 28px;

    &--container {
      max-width: 832px;
      margin: 0 auto;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.pt5 {
    padding-top: 5rem;
}