.article-slider {
  max-width: 1440px;
  margin: 0 auto;
  .main__subtitle {
    margin-bottom: 11.5rem;
  }
}

.slider {
  .slick-list {
    padding: 0 15% !important;
  } 

  &__item {
    margin: 0 2.5rem;

    @include md-max {
      margin: 0 1.5rem;
    }

    img {
      margin: 0 auto;
    }
  }
}
