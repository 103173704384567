.main {
  background-color: #fff;
  position: relative;

  &__title {
    margin: 0;

    @include md-max {
      text-align: center;
    }

    span {
      display: block;
    }
  }

  &__subtitle {
    margin: 0;
    color: $tc1;
    font-family: $ff-text;
    font-size: 5rem;
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 7.1rem;

    @include md-max {
      text-align: center;
    }

    @include sm-max {
      font-size: 4rem;
      line-height: 5.1rem;
    }

    &__nav {
      position: absolute;
      bottom: 5rem;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &__link {
      margin: 0 3.5rem;
      color: $accent2;
      font-family: $ff-text;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
      transition: all 0.3s ease;
    }

    &__link:hover,
    &__link.active {
      color: $accent1;
    }
  }

  &__tags-list {
    padding-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include sm-max {
      justify-content: center;
    }
  }

  &__tag {
    margin-right: 1.5rem;
    color: $accent1;
    font-family: $ff-text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 24px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;

    @include sm-max {
      font-size: 16px;
      margin-right: 1.5rem;

      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }

    // &:hover {
      // opacity: 0.7;
    // }

    &.active {
      color: $accent2;
    }
  }

  &__posts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10rem;
    position: relative;
    background-repeat: no-repeat;
    background-position: center 30rem;

    @include md-max {
      flex-direction: column;
      padding-bottom: 1rem;
    }
  }
}

.post {
  padding-bottom: 5rem;
  position: relative;
  flex: 0 0 49%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.even {
    top: 10rem;

    @include md-max {
      top: 0;
    }
  }

  &__image {
    margin-bottom: 3.7rem;
    transition: all 0.7s ease;
    position: relative;

    &:hover {
      transform: scale(0.95);
      .darkened::before {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    &-up {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__title, &__category {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin-bottom: 1.9rem;
    color: $tc1;
    font-family: $ff-text;
    font-weight: 300;
    letter-spacing: 0.53px;
    // text-align: center;
  }

  &__category {
    color: $tc3;
    font-family: $ff-text;
    font-size: 16px;
    line-height: 16px;
  }

  &-bg {
    // position: absolute;
    // top: 0;
    // bottom: auto;
    // left: 50%;
    text-align: center;
    z-index: 0;
    font-family: $ff-title;
    font-size: 12rem;
    margin-bottom: 20rem;

    &.sticky {
      position: fixed;
    }

    &.sticky {
      position: fixed;
    }

    @include lg-max {
      display: none;
    }

    // @include md-max {
    //   left: 0;
    //   transform: translate(-30%, 0);
    //   opacity: 0.1;
    // }
  }
}

span.subtitle_ {
  font-size: 50px;
  font-family: $ff-title;
}

#wpcf7-f1248-o1 {
  max-width: 100%;
}

.wpcf7-response-output {
  display: none !important;
}

.back-to-top {
  position: fixed;
  background-color: $accent2;
  box-shadow: 0 0 4px 1px rgba(#000, .3);
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  text-decoration: none;
  font-family: $ff-title;
  visibility: hidden;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 22px;
    transition: transform .2s ease;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

}

.back-to-top:hover {
  opacity: 0.7;
}

p strong,
strong,
p b {
  font-weight: 600;
}
