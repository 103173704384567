.filter {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    @include md {
      margin: 0 -9px;
    }

    > * {
      margin: 7px 6px;

      @include md {
        margin: 12px 9px;
      }
    }
  }
}

