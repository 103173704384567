.article-page {
  .container-max {
      min-height: auto;
  }

  // &__hero {
  //   max-width: 1440px;
  //   margin: -8rem auto 0;
  //   padding: 11.7rem 0;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // &__hero-logo {
  //   max-width: 250px;
  // }

  .container-max {
    padding: 0;
  }
}
