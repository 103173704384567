.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.df {
  display: flex;
}

.ovh {
  overflow: hidden;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}
