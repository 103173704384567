[data-aos="block-scale"] {
    max-width: 1920px;
    margin-top: -40px;
    transition-property: max-width, margin-top;
    &.aos-animate {
        max-width: 1200px;
    }
  }

[data-aos="max-width"] {
    max-width: 1920px;
    transition-property: max-width;
    &.aos-animate {
        max-width: 1200px;
    }
  }

[data-aos="full-screen"] {
    max-width: 1200px;
    min-height:500px;
    transition-property: max-width, min-height;
    &.aos-animate {
        max-width: 100vw;
        min-height: 100vh;
    }
  }

[data-aos="greeting-zoom"] {
    transform: scale(1);
    margin-bottom: 20rem;
    transition-property: transform, margin-bottom;
    &.aos-animate {
        transform: scale(1.3);
        margin-bottom: 10rem;
    }
  }


