.home-hero {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  text-align: center;

  display: flex;
  align-items: center;
  overflow: hidden;

  &__content {
    max-width: 90%;
    margin: 0 auto;
    // padding: 15rem 0;
    padding: 0 0 15rem;
    position: relative;
    z-index: 1;
  }

  &__award {
    display: block;
    width: 40vw;
    max-width: 350px;
    margin: 0 auto;
  }

  &__img {
    @include object-fit(cover, center);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  &__title {
    color: $white;
    text-transform: lowercase;
    margin: 0;

    line-height: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);

    span {
      display: block;

      &:first-child {
        font-size: 11rem;

        @include lg-max {
          font-size: 9vw;
        }
      }

      &:not(:first-child) {
        font-size: 6rem;
        font-weight: 400;

        @include lg-max {
          font-size: 4.6vw;
        }
      }
    }
  }
}
