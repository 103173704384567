.menu-btn {
  position: relative;
  display: inline-block;
  height: auto;
  margin: 0;
  padding: 0;
  color: inherit;
  background: 0 0;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
  text-indent: inherit;
  text-decoration: none;
  height: 25px;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &__title {
    display: none;

    @media (min-width: 768px) {
      display: inline-block;
      margin: 0 25px 0 0;
      font-size: 20px;
      letter-spacing: -0.03em;
    }
  }

  &__box {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 25px;
    line-height: 25px;
    vertical-align: middle;
    transition: color 0.2s;

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 2px;
      margin: 5px 0;
      top: 0;
      z-index: 1;
      backface-visibility: hidden;
      background: currentColor;
      transition: top 0.3s 0.3s, transform 0.3s, background-color 0.3s;
    }

    &:hover &__box {
      color: #fff;
      transition-delay: 0.15s;

      &:before {
        @media (min-width: 1024px) {
          top: -80px;
          right: -80px;
          left: -80px;
          bottom: -80px;
        }
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      right: -20px;
      left: -20px;
      bottom: -20px;
    }
  }
}

.nav-bar--inverse .menu-btn,
.menu--inverse .menu-btn {
  color: #fff;
}

.menu--open .menu-btn {
  color: #000;
}

.menu--open .menu-btn__box span:first-child {
  animation: btn_menu-open-top 0.9s;
  top: 4px;
  transform: rotate(45deg) scaleX(1);
  transition-duration: 0s, 0s;
}

.menu--open .menu-btn__box span:last-child {
  animation: btn_menu-open-bottom 0.9s;
  top: -3px;
  transform: rotate(-45deg) scaleX(1);
  transition-duration: 0s, 0s;
}

@keyframes btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    transform: rotate(45deg) scaleX(1);
    transform-origin: left center;
  }
}

@keyframes btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(1);
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    transform: rotate(-45deg) scaleX(1);
    transform-origin: right center;
  }
}
