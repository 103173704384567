.history-module {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: relative;
  padding-bottom: 10rem;

  &__header {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10rem;
    position: relative;

    @include lg-max {
      padding: 5rem;
    }
    @include sm-max {
      padding: 2rem;
    }
  }

  &__header-border {
    background-color: $accent1;
    position: absolute;
    opacity: 0;

    &--top {
      left: 0;
      top: 0;
    }
    &--bottom1 {
      right: 68%;
      bottom: 0;
    }
    &--bottom2 {
      right: 0;
      bottom: 0;
    }
    &--left {
      left: 0;
      bottom: 0;
    }
    &--right {
      right: 0;
      top: 0;
    }

    &--top,
    &--bottom1,
    &--bottom2 {
      width: 100%;
      height: 2px;
    }

    &--left,
    &--right {
      height: 100%;
      width: 2px;
    }
  }

  &__timeline-wrapper {
    position: relative;
    padding-top: 5rem;

    @include sm-max {
      padding-top: 2.5rem;
    }
  }

  &__line {
    width: 2px;
    position: absolute;
    left: 32%;
    top: 0;
    background: $accent1;
    opacity: 0;
  }

  &__row {
    display: flex;
    margin-bottom: 5rem;

    @include sm-max {
      margin-bottom: 2.5rem;
    }
  }

  &__date {
    padding: 0 10rem 0 2.5rem;
    width: 32%;
    text-align: right;
    color: $tc3;
    position: relative;
    align-self: start;

    @include lg-max {
      padding: 0 5rem 0 0;
    }
    @include sm-max {
      padding: 0 2.5rem 0 0;
    }
  }

  &__dot {
    position: absolute;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
    border: 2px solid $accent1;
    background-color: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  // &__row:last-child &__date::before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: -2px;
  //   background-color: #fff;
  //   width: 2px;
  //   height: 100%;
  // }

  &__date-title {
    line-height: normal;

    @include lg-max {
      font-size: 16px;
    }
    @include sm-max {
      text-align: left;
      font-size: 12px;
    }
  }

  &__content {
    padding: 0 10rem;
    width: 68%;
    color: $tc2;

    @include lg-max {
      padding: 0;
      padding-left: 2rem;
    }
  }
  &__subtitle {
    color: $tc1;
    margin-bottom: 3rem;
  }
}
