.app-about {
  display: flex;
  color: $tc2;

  @include md-max {
    flex-direction: column;
  }

  &__title {
    padding-right: 2rem;
    flex-basis: 35%;
    flex-shrink: 0;

    @include md-max {
      margin-bottom: 5rem;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    p {
      margin-bottom: 1rem;
      flex-grow: 1;
    }
  }

  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__tag {
    margin-right: 3rem;
    color: $accent1;
    font-family: $ff-text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    @include sm-max {
      font-size: 16px;
      margin-right: 1.5rem;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__list {
    width: 100%;
    columns: 2;

    @include sm-max {
      columns: 1;
    }

    li {
      margin-bottom: 2rem;
      padding: 0 25px;
      width: 100%;
      color: $tc2;
      font-family: $ff-text;
      font-weight: normal;
      font-size: 18px;
      letter-spacing: 0.3px;
      line-height: 28px;
      position: relative;

      @include sm-max {
        margin-left: 20px;
      }

      &::before { 
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 9px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $accent1;
      }
    }
  }
}
