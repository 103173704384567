.offer {
    color: $tc2;
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
     

        @include sm-max {
            padding: 2.6rem 0; 
        }
    }

    &__item {
        margin-top: 10rem;
        flex: 0 0 30.2%;
       
        @include md-max {
            flex: 0 0 47%;
        }

        @include sm-max {
            margin-top: 3rem;
            flex: 0 0 100%;
        }
    }

    &__subtitle {
        margin-bottom: 3rem;
        color: $tc1;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 28px;

        @include sm-max {
            margin-bottom: 1rem;
        }
    }
}