.career-details {
  &__title {

    @include xl-max {
      font-size: 4.5rem;
      line-height: normal;
    }

    @include lg-max {
      font-size: 3rem;
      line-height: 4rem;
    }

    @include sm-max {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &__block {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 11rem;

      @include md-max {
        margin-bottom: 5rem;
      }
      @include sm-max {
        margin-bottom: 3rem;
      }
    }

    @include md-max {
      flex-direction: column;
    }
  }

  &__left {
    padding-right: 7.5rem;
    width: 50%;
    margin-bottom: 3rem;

    @include md-max {
      padding: 0;
      width: 100%;
      text-align: center;
    }
  }

  &__content {
    padding-left: 2.5rem;
    width: 50%;
    @include md-max {
      padding: 0;
      width: 100%;
    }
  }
}
