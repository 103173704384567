html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include lg-max {
    font-size: 50%;
  }
}

@include xl-max {
  html {
    font-size: 50%;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

body {
  display: block;
  position: relative;
  font-family: $ff-text;
  background: #fff;
  min-width: 320px;
  color: $tc1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 1.8rem;
}

.site-wrapper {
  height: 100vh;
  background-color: #fff;

  &__inner {
    width: 100vw;
    overflow-x: hidden;
  }
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 0.7;
}

:-ms-input-placeholder {
  opacity: 0.7;
}

::ms-input-placeholder {
  opacity: 0.7;
}
