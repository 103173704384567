.main-footer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 7.5rem;
  overflow: hidden;
  background: #fff;

  @include lg-max {
    padding: 5rem;
  }

  @include md-max {
    flex-direction: column-reverse;
  }

  &__logo {
    width: 240px;
    flex-shrink: 0;

    @include md-max {
      margin-top: 15px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    @include md-max {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__awards {
    @include md-max {
      margin-top: 5rem;  
    }

    > *:not(:last-child) {
      margin-right: 2rem;
    }

    a:hover img {
      transform: scale(0.95);
    }

    img {
      transition: transform .3s ease;
      height: 8rem;
    }
  }

  &__bottom-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;

    @include lg-max {
      flex-direction: column;
    }

    a {
      text-decoration: underline;
      color: inherit;

      // &:hover {
      //   color: $accent2;
      // }
    }

    p {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      @include md-max {
        flex-direction: column;
      }

      > * {
        @include md-max {
          margin-bottom: 1rem;
        }
      }

      > *:not(:last-child) {
        margin-right: 2rem;

        @include md-max {
          margin-right: 0;
        }
      }
    }
  }
}
