.hero-media {
  position: relative;
  padding-top: 16rem;
  background-color: #fff;

  @include lg {
    padding-top: 20rem;
  }

  img {
    position: relative;
  }

  &__box {
    max-width: 120rem;
    //   padding: 15rem 0 0;
    margin: 5rem auto 0;
    position: relative;

    @include xl-max {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }

    @include md-max {
      padding-top: 2rem;
      margin: 0 auto;
    }

    &--lg {
      padding: 18rem 0 0;

      @include md-max {
        padding-top: 2rem;
      }
    }
  }

  &__text {
    width: 100%;
    padding: 0;
    margin: 0 auto 5rem;
    z-index: 0;

    @include md-max {
      position: relative;
    }
  }

  h1 {
    color: $tc1;
    text-transform: none;
    text-align: center;
  }

  // @include md-max {
  //     padding: 1rem 0 0;
  // }
}
