.menu {
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.6);

    opacity: 1;
  }

  &--open &__backdrop {
    pointer-events: auto;
  }

  &__box {
    display: none;
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 50px 30px 90px 45px;
    background: #fff;

    @media (min-height: 550px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 0 45px;
    }

    @media (min-width: 768px) {
      width: auto;
      left: auto;
      padding: 0 98px;
    }

    @media (min-width: 768px) and (min-height: 550px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 98px 0;
    }

    @media (min-width: 768px) and (min-height: 600px) {
      padding: 40px 98px 0;
    }

    @media (min-width: 1600px) and (min-height: 700px) {
      padding: 50px 98px 0;
    }
  }

  &__body {
    width: 100%;
    padding: 5rem 0;

    @media (min-width: 768px) and (min-height: 550px) {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  &__footer {
    padding: 20px 0 30px 0;

    @media (min-width: 768px) and (min-height: 550px) {
      padding: 0 0 50px 0;
    }

    @media (min-width: 1600px) and (min-height: 700px) {
      padding: 0 0 84px 0;
    }
  }

  &__title {
    color: rgba(0, 0, 0, 0.3);
    font-size: 16px;
    line-height: 100%;
    display: block;
    font-weight: normal;
  }

  &__mail {
    display: inline-block;
    padding: 12px 0 5px 0;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: #000;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.3);
    }

    &::after {
      background: #000;
      transform: scaleX(0);
      transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }

  &__list {
    margin: -4px 0;
    padding: 30px 0 0 0;

    @media (min-width: 1600px) and (min-height: 700px) {
      margin: -9px 0;
      padding: 60px 0 0 0;
    }

    li {
      position: relative;
      display: block;
      margin: 4px 0;

      @media (min-width: 1600px) and (min-height: 700px) {
        margin: 9px 0;
      }

      &.active a::before {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }

  &__link {
    display: inline-block;
    position: relative;
    font-weight: 400;
    letter-spacing: -0.05em;
    font-size: 34px;
    line-height: 1.23;
    text-decoration: none;

    @media (min-width: 360px) {
      font-size: 38px;
    }

    @media (min-width: 768px) and (min-height: 550px) {
      font-size: 45px;
    }

    @media (max-height: 550px) {
      font-size: 28px;
    }

    @media (max-height: 450px) {
      font-size: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      height: 1px;
      transform: scaleX(0);
      transform-origin: right center;
      background: currentColor;
      transition: transform 0.5s, transform-origin 0s;
    }

    @media (min-width: 768px) {
      bottom: -7px;
    }

    em {
      display: block;
      position: relative;
      overflow: hidden;
      padding: 0 0.01em 0 0;
      font-style: normal;
    }

    span {
      display: inline-block;
      color: transparent;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    }

    span::after,
    span::before {
      content: attr(data-text);
      display: block;
      position: absolute;
      color: #000;
    }

    span::before {
      top: 0;
      transform: skewY(0);
      transform-origin: right bottom;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    }

    span::after {
      top: 105%;
      transform: skewY(7deg);
      transform-origin: left top;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:focus span,
    &:hover span {
      transform: translateY(-105%);

      @media (pointer: coarse) {
        transform: none;
      }

      &::before {
        transform: skewY(7deg);

        @media (pointer: coarse) {
          transform: none;
        }
      }

      &::after {
        transform: skewY(0);

        @media (pointer: coarse) {
          transform: none;
        }
      }
    }
  }
}

.menu-toggle {
  position: fixed;
  top: 29px;
  right: 20px;
  z-index: 151;

  @media (min-width: 768px) and (min-height: 550px) {
    top: 44px;
    right: 30px;
  }

  @media (min-width: 1200px) and (min-height: 700px) {
    top: 73px;
    right: 60px;
  }
}
