.app-gallery {
  padding: 10rem 0 0 0;

  @include md-max {
    padding: 5rem 0 10rem 0;
  }

  .container {
    @include md-max {
      padding: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    .slick-list {
      @include md-max {
        padding: 0 15% !important;
      }
    }

    &.small {
      justify-content: center;
    }
  }

  &__item {
    padding: 0 1.3rem;
    margin-bottom: 2.6rem;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include md-max {
      width: auto;
      padding: 0;
      margin: 0 1.5rem;
    }

    &:nth-child(2n) {
      top: -10rem;

      @include md-max {
        top: 0;
      }
    }

    &:nth-child(4n) {
      top: 5rem;

      @include md-max {
        top: 0;
      }
    }
  }
}
