.social-module {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include lg-max {
        flex-direction: column;
    }

    &__title {
        width: 47%;

        @include lg-max {
            width: 100%;
            margin-bottom: 3rem;
            text-align: center;
        }
    }

    .social__list {
        width: 48%;
        justify-content: center;

        @include lg-max {
            width: 100%;
        }

        li:first-child .social__link {
            margin-left: 0; 
        }
    }
    .social__link {
        height: 4.8rem;
        width: 4.8rem;
        margin-left: 8rem;

        @include md-max {
            margin-left: 4rem;
        }

        i {
            font-size: 4.8rem;
        }
    }
}