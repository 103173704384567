.app-team {
  &__title {
    max-width: 708px;
    text-transform: uppercase;

    @include md-max {
      text-align: center;
    }
  }

  &__block {
    padding: 10rem 0;
    background-color: $gray;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .container {
      max-width: 1050px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2.6rem;
    margin-top: -2.6rem;

    @include sm-max {
      margin-left: 0;
      margin-top: 0;
    }
  }

  &__item {
    margin-left: 2.6rem;
    margin-top: 2.6rem;
    padding: 3rem;
    flex: 0 0 calc(33.3% - 2.6rem);
    background-color: #fff;

    @include md-max {
      flex: 0 0 calc(50% - 2.6rem);
    }

    @include sm-max {
      flex: 0 0 100%;
      margin-left: 0;
    }
  }

  &__name {
    margin-bottom: 1rem;
    color: $accent2;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 21px;
  }

  &__role {
    color: $tc3;
    font-family: $ff-text;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 14px;
  }
}
