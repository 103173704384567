.cta-ripple {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  height: 49px;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: -0.01em;
  background: #fff;
  border-radius: 68px;
  user-select: none;
  transition: transform 0.2s ease;
  color: $tc1;

  &:hover {
    transform: translateY(-3px);
  }

  @include md {
    padding: 0 32px;
    height: 65px;
    font-size: 18px;
    border-radius: 90px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: inherit;
    transition: border-color 0.2s;
  }

  &__view {
    position: relative;
    top: -1px;
    display: block;
    overflow: hidden;
  }

  &__title {
    display: block;
    position: relative;
    z-index: 2;
    transition: color 0.2s, transform 0.4s;
    transform-origin: left top;

    &::after {
      content: attr(data-text);
      display: block;
      position: absolute;
      top: 150%;
      left: 0;
      transform: skewY(5deg);
      transform-origin: left top;
      transition: transform 0.4s;
    }
  }

  &:hover &__title {
    transform: translateY(-150%) skewY(-7deg);

    &::after {
      transform: skewY(7deg);
    }
  }

  &.active &__title {
    color: #fff;
  }

  &__ripple {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    contain: strict;
    border-radius: inherit;

    span {
      display: block;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      background: currentColor;
      border-radius: 50%;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0, 1),
        border-radius 0.5s cubic-bezier(0.4, 0, 0, 1);
    }
  }

  &.active &__ripple {
    mask-image: radial-gradient(circle, #fff 100%, #000 100%);

    span {
      border-radius: 0;
      transform: translateY(0);
      transition-duration: 0s, 0s;
      animation: ripple-in 0.5s cubic-bezier(0.4, 0, 0, 1);
    }
  }
}

@keyframes ripple-in {
  0% {
    border-radius: 100%;
    transform: translateY(-100%);
  }

  100% {
    border-radius: 0;
    transform: translateY(0);
  }
}
