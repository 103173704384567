.img--big {
  height: 60vw;

  @include rwd(450) {
    height: 50vw;
  }

  @include md {
    height: 400px;
  }

  @include lg {
    height: 500px;
  }

  @include xl {
    height: 600px;
  }
}