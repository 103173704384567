.main-nav {
  &__list {
    display: flex;
    position: relative;
    z-index: 2;

    @include lg-max {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__list li {
    margin: 0 0 0 5rem;

    @include lg-max {
      margin: 0;
    }
  }

  &__link {
    display: inline-block;
    color: $tc1;
    font-family: $ff-text;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 14px;
    transition: all 0.3s ease-in;
    position: relative;
    user-select: none;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: $tc1;
      transition: all 0.2s ease;
      width: 0;
    }

    &:hover::before {
      width: 100%;
    }

    @include lg-max {
      padding: 2rem;
    }
  }

  &__list li.active .main-nav__link {
    // font-weight: bold;
    // color: $accent2;
    &::before {
      width: 100%;
    }
  }

  // &__link:hover {
  //   // color: $accent2;
  // }

  @include lg-max {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $white;
    padding: 12rem 0 0;
    transition: all 0.5s ease;
  }

  &.active {
    top: 0;
  }
}

.second-nav {
  &__list {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include lg-max {
      padding: 2rem 0;
    }
  }

  &__list li.active .main-nav__link {
    font-weight: bold;
    color: $accent1;
  }

  &__link {
    display: inline-block;
    margin: 1rem 3.5rem;
    color: $accent2;
    font-family: $ff-text;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 18px;
    transition: all 0.3s ease;
  }

  &__link:hover {
    color: $accent1;
  }

  &__link.active {
    font-weight: bold;
    color: $accent1;
  }
}
