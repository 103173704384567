.small-gallery {
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
    &__list {
        display: flex;
        width: 110%;
        margin-left: -5%;
    }
    &__list:not(:last-child) {
        margin-bottom: 3rem;

        @include md-max {
            margin-bottom: 1.5rem;
        }
        @include sm-max {
            margin-bottom: 0.5rem;
        }
    }

    &__item:not(:last-child) {
        margin-right: 3rem;

        @include md-max {
            margin-right: 1.5rem;
        }

        @include sm-max {
            margin-right: 0.5rem;
        }
    }
}
